.progress-bar__wrapper {
  background: var(--ink-washed);
  border-radius: 0.5rem;
  overflow: hidden;
  height: 0.75rem;
  display: flex;
}

.progress-bar__bar {
  transition: width 0.5s ease;
  background: var(--green);
  height: 100%;
  width: 0%;
}
