:root {
  /* 
  Font family
  DEPRECATED - We should be using compass-ui typography components going forward 
  */
  --bold: 'Suisse-Bold';
  --semi-bold: 'Suisse-Semibold';
  --book: 'Suisse-Book';
  --monospace: 'Suisse-Mono';

  /* Font size */
  --font-size-headline: 6rem;
  --font-size-subheadline: 5rem;
    --font-size-2: 3rem;
  --font-size-4: 1.5rem;
  --font-size-5: 1.25rem;
  --font-size-6: 1.125rem;
  --font-size-7: 1rem;
  --font-size-8: 0.875rem;
  --font-size-9: 0.75rem;

  /* Line height */
  --line-height-solid: 1;
  --line-height-title: 1.15;
  --line-height-title-2: 1.25;
  --line-height-copy: 1.5;
  --line-height-longform: 1.75;
}
