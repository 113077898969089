@import '../variables/index.css';

.shadow-smallest {
  box-shadow: var(--box-shadow-smallest);
}
.shadow-1 {
  box-shadow: var(--box-shadow-1);
}
.shadow-2 {
  box-shadow: var(--box-shadow-2);
}
.shadow-3 {
  box-shadow: var(--box-shadow-3);
}
.shadow-4 {
  box-shadow: var(--box-shadow-4);
}
.shadow-5 {
  box-shadow: var(--box-shadow-5);
}

@media screen and (min-width: 30em) {
  .shadow-smallest-ns {
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .shadow-1-ns {
    box-shadow: var(--box-shadow-1);
  }
  .shadow-2-ns {
    box-shadow: var(--box-shadow-2);
  }
  .shadow-3-ns {
    box-shadow: var(--box-shadow-3);
  }
  .shadow-4-ns {
    box-shadow: var(--box-shadow-4);
  }
  .shadow-5-ns {
    box-shadow: var(--box-shadow-5);
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .shadow-smallest-m {
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .shadow-1-m {
    box-shadow: var(--box-shadow-1);
  }
  .shadow-2-m {
    box-shadow: var(--box-shadow-2);
  }
  .shadow-3-m {
    box-shadow: var(--box-shadow-3);
  }
  .shadow-4-m {
    box-shadow: var(--box-shadow-4);
  }
  .shadow-5-m {
    box-shadow: var(--box-shadow-5);
  }
}

@media screen and (min-width: 60em) {
  .shadow-smallest-l {
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .shadow-1-l {
    box-shadow: var(--box-shadow-1);
  }
  .shadow-2-l {
    box-shadow: var(--box-shadow-2);
  }
  .shadow-3-l {
    box-shadow: var(--box-shadow-3);
  }
  .shadow-4-l {
    box-shadow: var(--box-shadow-4);
  }
  .shadow-5-l {
    box-shadow: var(--box-shadow-5);
  }
}
