.spend-bonus__header {
  margin-bottom: 1.5rem;
  line-height: 1.6rem;
  font-size: 1.25rem;
}

.spend-bonus__header--highlight {
  color: var(--green);
}

.spend-bonus__message {
  line-height: 1.5rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.spend-bonus__message--highlight {
  color: var(--ink);
  font-weight: 700;
}
