.clip-tracker-bar-container {
  display: flex;
  position: relative;
  bottom: -15px;
  margin-left: 6px;
}

.clip-tracker-bar-completed-bar {
  height: 6px;
  background-color: var(--green);
}

.clip-tracker-bar-pending-bar {
  height: 6px;
  background-color: var(--yellow);
}

.clip-tracker-bar-upcoming-bar {
  height: 6px;
  background-color: var(--grey-lightest);
}

.clip-tracker-bar-node-offset {
  display: flex;
  justify-content: space-between;
}

.clip-tracker-bar-node {
  height: 24px;
  width: 24px;
  border-radius: 50px;
  margin-bottom: 5px;
}
