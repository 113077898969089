@import '../variables/index.css';
/* DEPRECATED - Most of these can be removed when we are only using compass typography everywhere */

/* 

Semantic element defaults

*/

body,
.sans-serif {
  font-family: var(--book); /* default font */
}

b,
.b,
strong {
  font-family: var(--semi-bold);
}

/* 
  use strong when the color should be ink. Use .b or b when you don't want to override the color
  yes, this is janky, but it's temporary we can replace all with the compass B component
 */
strong {
  color: var(--ink);
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ink);
  font-weight: 800;
}

h1,
h2,
h5 {
  font-family: var(--semi-bold);
}

h3,
.f3,
h4,
.f3-half {
  font-family: var(--bold);
}

h1,
.f1 {
  font-size: 72px;
  line-height: 72px;
  letter-spacing: -2px;
  margin: 0;
}

h2,
.f2 {
  font-size: 48px;
  line-height: 55.2px;
  letter-spacing: -1.5px;
  margin: 0;
}

h3,
.f3 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
  margin: 12px 0;
}

h4,
.f3-half {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0;
  margin: 0;
}

h5,
.f4 {
  font-size: 18px;
  line-height: 22.5px;
  letter-spacing: 0;
  margin: 0;
}

/* Paragraph text */
p {
  font-family: var(--book);
  color: var(--ink-light);
}

/* Paragraph 2 (long form) */
.p2 {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0;
  margin-bottom: 32px;
}

/* Paragraph 3 (standard) */
p,
.p3 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 24px;
}

/* Paragraph 4 (small body) */
.p4 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  margin-bottom: 20px;
}

.monospace {
  font-family: var(--monospace);
}

/* Tablet/Mobile screens typography */
@media screen and (max-width: 60em) {
  h1,
  .f1 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
    margin: 0;
  }

  h2,
  .f2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }

  h3,
  .f3,
  h4,
  .f3-half,
  h5,
  .f4 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    margin-bottom: 0;
  }

  p,
  .p3 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
    margin-bottom: 24px;
  }

  .p2,
  .p4 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    margin-bottom: 20px;
  }
}

/* 

Font size 

*/

.f-headline {
  font-size: var(--font-size-headline);
}
.f-subheadline {
  font-size: var(--font-size-subheadline);
}
.f5 {
  font-size: var(--font-size-5);
}
.f6 {
  font-size: var(--font-size-6);
}
.f7 {
  font-size: var(--font-size-7);
}

@media screen and (min-width: 30em) {
  .f-headline-ns {
    font-size: var(--font-size-headline);
  }
  .f-subheadline-ns {
    font-size: var(--font-size-subheadline);
  }
  .f5-ns {
    font-size: var(--font-size-5);
  }
  .f6-ns {
    font-size: var(--font-size-6);
  }
  .f7-ns {
    font-size: var(--font-size-7);
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .f-headline-m {
    font-size: var(--font-size-headline);
  }
  .f-subheadline-m {
    font-size: var(--font-size-subheadline);
  }
  .f5-m {
    font-size: var(--font-size-5);
  }
  .f6-m {
    font-size: var(--font-size-6);
  }
  .f7-m {
    font-size: var(--font-size-7);
  }
}

@media screen and (min-width: 60em) {
  .f-headline-l {
    font-size: var(--font-size-headline);
  }
  .f-subheadline-l {
    font-size: var(--font-size-subheadline);
  }
  .f2-l {
    font-size: var(--font-size-2);
  }
  .f5-l {
    font-size: var(--font-size-5);
  }
  .f6-l {
    font-size: var(--font-size-6);
  }
  .f7-l {
    font-size: var(--font-size-7);
  }
}

/* 

Line height 

*/

.lh-solid {
  line-height: var(--line-height-solid);
}
.lh-title {
  line-height: var(--line-height-title);
}
.lh-copy {
  line-height: var(--line-height-copy);
}

@media screen and (min-width: 30em) {
  .lh-solid-ns {
    line-height: var(--line-height-solid);
  }
  .lh-title-ns {
    line-height: var(--line-height-title);
  }
  .lh-copy-ns {
    line-height: var(--line-height-copy);
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .lh-solid-m {
    line-height: var(--line-height-solid);
  }
  .lh-title-m {
    line-height: var(--line-height-title);
  }
  .lh-copy-m {
    line-height: var(--line-height-copy);
  }
}

@media screen and (min-width: 60em) {
  .lh-solid-l {
    line-height: var(--line-height-solid);
  }
  .lh-title-l {
    line-height: var(--line-height-title);
  }
  .lh-copy-l {
    line-height: var(--line-height-copy);
  }
}
