.payment-streak-container {
  gap: 16px;
}

.payment-streak-counter-outer-container {
  height: 95px;
  width: 95px;
  border-color: var(--green);
}

.payment-streak-counter-inner-container {
  height: 80px;
  width: 80px;
  border-color: var(--green);
}
