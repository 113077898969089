.upcoming-payments-gap-16 {
  gap: 16px;
}

.upcoming-payments-gap-4 {
  gap: 4px;
}

.upcoming-payments-margin-left-auto {
  margin-left: auto;
}

.upcoming-payments-scheduled-payments-border-bottom {
  border-bottom: 1px solid var(--grey-lightest);
}
