.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.translate-x-0 {
  transform: translateX(0px);
}

.translate-x-100 {
  transform: translateX(100%)
}
