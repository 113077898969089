@import '../variables/index.css';

/* Border radius */

.br0 {
  border-radius: var(--border-radius-none);
}
.br1 {
  border-radius: var(--border-radius-1);
}
.br2 {
  border-radius: var(--border-radius-2);
}
.br3 {
  border-radius: var(--border-radius-3);
}
.br4 {
  border-radius: var(--border-radius-4);
}
.br-100 {
  border-radius: var(--border-radius-circle);
}
.br-pill {
  border-radius: var(--border-radius-pill);
}
.br-right-2 {
  border-top-right-radius: var(--border-radius-2);
  border-bottom-right-radius: var(--border-radius-2);
}
.br-right-3 {
  border-top-right-radius: var(--border-radius-3);
  border-bottom-right-radius: var(--border-radius-3);
}
.br-right-4 {
  border-top-right-radius: var(--border-radius-4);
  border-bottom-right-radius: var(--border-radius-4);
}

@media screen and (min-width: 30em) {
  .br0-ns {
    border-radius: var(--border-radius-none);
  }
  .br1-ns {
    border-radius: var(--border-radius-1);
  }
  .br2-ns {
    border-radius: var(--border-radius-2);
  }
  .br3-ns {
    border-radius: var(--border-radius-3);
  }
  .br4-ns {
    border-radius: var(--border-radius-4);
  }
  .br-100-ns {
    border-radius: var(--border-radius-circle);
  }
  .br-pill-ns {
    border-radius: var(--border-radius-pill);
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .br0-m {
    border-radius: var(--border-radius-none);
  }
  .br1-m {
    border-radius: var(--border-radius-1);
  }
  .br2-m {
    border-radius: var(--border-radius-2);
  }
  .br3-m {
    border-radius: var(--border-radius-3);
  }
  .br4-m {
    border-radius: var(--border-radius-4);
  }
  .br-100-m {
    border-radius: var(--border-radius-circle);
  }
  .br-pill-m {
    border-radius: var(--border-radius-pill);
  }
}

@media screen and (min-width: 60em) {
  .br0-l {
    border-radius: var(--border-radius-none);
  }
  .br1-l {
    border-radius: var(--border-radius-1);
  }
  .br2-l {
    border-radius: var(--border-radius-2);
  }
  .br3-l {
    border-radius: var(--border-radius-3);
  }
  .br4-l {
    border-radius: var(--border-radius-4);
  }
  .br-100-l {
    border-radius: var(--border-radius-circle);
  }
  .br-pill-l {
    border-radius: var(--border-radius-pill);
  }
}

/* Border width */

.bw0 {
  border-width: var(--border-width-none);
}
.bw1 {
  border-width: var(--border-width-1);
}
.bw2 {
  border-width: var(--border-width-2);
}
.bw3 {
  border-width: var(--border-width-3);
}
.bw4 {
  border-width: var(--border-width-4);
}
.bw5 {
  border-width: var(--border-width-5);
}

/* Resets */
.bt-0 {
  border-top-width: var(--border-width-none);
}
.br-0 {
  border-right-width: var(--border-width-none);
}
.bb-0 {
  border-bottom-width: var(--border-width-none);
}
.bl-0 {
  border-left-width: var(--border-width-none);
}

@media screen and (min-width: 30em) {
  .bw0-ns {
    border-width: var(--border-width-none);
  }
  .bw1-ns {
    border-width: var(--border-width-1);
  }
  .bw2-ns {
    border-width: var(--border-width-2);
  }
  .bw3-ns {
    border-width: var(--border-width-3);
  }
  .bw4-ns {
    border-width: var(--border-width-4);
  }
  .bw5-ns {
    border-width: var(--border-width-5);
  }
  .bt-0-ns {
    border-top-width: var(--border-width-none);
  }
  .br-0-ns {
    border-right-width: var(--border-width-none);
  }
  .bb-0-ns {
    border-bottom-width: var(--border-width-none);
  }
  .bl-0-ns {
    border-left-width: var(--border-width-none);
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .bw0-m {
    border-width: var(--border-width-none);
  }
  .bw1-m {
    border-width: var(--border-width-1);
  }
  .bw2-m {
    border-width: var(--border-width-2);
  }
  .bw3-m {
    border-width: var(--border-width-3);
  }
  .bw4-m {
    border-width: var(--border-width-4);
  }
  .bw5-m {
    border-width: var(--border-width-5);
  }
  .bt-0-m {
    border-top-width: var(--border-width-none);
  }
  .br-0-m {
    border-right-width: var(--border-width-none);
  }
  .bb-0-m {
    border-bottom-width: var(--border-width-none);
  }
  .bl-0-m {
    border-left-width: var(--border-width-none);
  }
}

@media screen and (min-width: 60em) {
  .bw0-l {
    border-width: var(--border-width-none);
  }
  .bw1-l {
    border-width: var(--border-width-1);
  }
  .bw2-l {
    border-width: var(--border-width-2);
  }
  .bw3-l {
    border-width: var(--border-width-3);
  }
  .bw4-l {
    border-width: var(--border-width-4);
  }
  .bw5-l {
    border-width: var(--border-width-5);
  }
  .bt-0-l {
    border-top-width: var(--border-width-none);
  }
  .br-0-l {
    border-right-width: var(--border-width-none);
  }
  .bb-0-l {
    border-bottom-width: var(--border-width-none);
  }
  .bl-0-l {
    border-left-width: var(--border-width-none);
  }
}

/* Border colors */

.b--ink {
  border-color: var(--ink);
}

.b--red {
  border-color: var(--red);
}

.b--light-purple {
  border-color: var(--purple-light);
}
