/* This styling is to override the compass-ui button padding that is 
  causing unwanted text wrapping on a small screen */
.cfu-plaid-button-container div[role='button'] {
  padding-left: 10px;
  padding-right: 10px;
}

.cfu-header-container {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column-reverse;
}

.cfu-header h3 {
  display: block;
}

.cfu-header-img {
  width: 25%;
  margin-bottom: 1rem;
}

@media (min-width: 640px) {
  .cfu-header-img {
    width: 15%;
  }
}

@media (min-width: 834px) {
  .cfu-header-container {
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 1.5rem;
  }

  .cfu-header {
    width: calc(100% / 1.5);
  }

  .cfu-header-img {
    width: auto;
    margin-left: auto;
    margin-bottom: 0;
  }
}
