.step-number {
  display: flex;
  height: 56px;
  min-width: 56px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: var(--teal-washed);
}

.link-container {
  overflow-x: auto;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copied-popup {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  width: 115px;
  transform: translate(-50%, -50%);
}

.referral-tile-hand {
  position: absolute;
  bottom: 0;
  right: -8px;
}

.referral-tile-tracks {
  position: absolute;
  top: -8px;
  right: 0;
}
