@import '../variables/index.css';

/* Height */

.h1 {
  height: var(--height-1);
}
.h2 {
  height: var(--height-2);
}
.h3 {
  height: var(--height-3);
}
.h4 {
  height: var(--height-4);
}
.h5 {
  height: var(--height-5);
}

/* We aren't overriding these so we just port them directly from tachyons */
.h-25 {
  height: 25%;
}
.h-50 {
  height: 50%;
}
.h-65 {
  height: 65%;
}
.h-75 {
  height: 75%;
}
.h-100 {
  height: 100%;
}
.min-h-100 {
  min-height: 100%;
}
.vh-25 {
  height: 25vh;
}
.vh-50 {
  height: 50vh;
}
.vh-75 {
  height: 75vh;
}
.vh-100 {
  height: 100vh;
}
.min-vh-100 {
  min-height: 100vh;
}

@media screen and (min-width: 30em) {
  .h1-ns {
    height: var(--height-1);
  }
  .h2-ns {
    height: var(--height-2);
  }
  .h3-ns {
    height: var(--height-3);
  }
  .h4-ns {
    height: var(--height-4);
  }
  .h5-ns {
    height: var(--height-5);
  }
  .h-25-ns {
    height: 25%;
  }
  .h-50-ns {
    height: 50%;
  }
  .h-75-ns {
    height: 75%;
  }
  .h-100-ns {
    height: 100%;
  }
  .min-h-100-ns {
    min-height: 100%;
  }
  .vh-25-ns {
    height: 25vh;
  }
  .vh-50-ns {
    height: 50vh;
  }
  .vh-75-ns {
    height: 75vh;
  }
  .vh-100-ns {
    height: 100vh;
  }
  .min-vh-100-ns {
    min-height: 100vh;
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .h1-m {
    height: var(--height-1);
  }
  .h2-m {
    height: var(--height-2);
  }
  .h3-m {
    height: var(--height-3);
  }
  .h4-m {
    height: var(--height-4);
  }
  .h5-m {
    height: var(--height-5);
  }
  .h-25-m {
    height: 25%;
  }
  .h-50-m {
    height: 50%;
  }
  .h-75-m {
    height: 75%;
  }
  .h-100-m {
    height: 100%;
  }
  .min-h-100-m {
    min-height: 100%;
  }
  .vh-25-m {
    height: 25vh;
  }
  .vh-50-m {
    height: 50vh;
  }
  .vh-75-m {
    height: 75vh;
  }
  .vh-100-m {
    height: 100vh;
  }
  .min-vh-100-m {
    min-height: 100vh;
  }
}

@media screen and (min-width: 60em) {
  .h1-l {
    height: var(--height-1);
  }
  .h2-l {
    height: var(--height-2);
  }
  .h3-l {
    height: var(--height-3);
  }
  .h4-l {
    height: var(--height-4);
  }
  .h5-l {
    height: var(--height-5);
  }
  .h-25-l {
    height: 25%;
  }
  .h-50-l {
    height: 50%;
  }
  .h-75-l {
    height: 75%;
  }
  .h-100-l {
    height: 100%;
  }
  .min-h-100-l {
    min-height: 100%;
  }
  .vh-25-l {
    height: 25vh;
  }
  .vh-50-l {
    height: 50vh;
  }
  .vh-75-l {
    height: 75vh;
  }
  .vh-100-l {
    height: 100vh;
  }
  .min-vh-100-l {
    min-height: 100vh;
  }
}

/* Width */

.w1 {
  width: var(--width-1);
}
.w2 {
  width: var(--width-2);
}
.w3 {
  width: var(--width-3);
}
.w4 {
  width: var(--width-4);
}
.w5 {
  width: var(--width-5);
}
.w6 {
  width: var(--width-6);
}
.w7 {
  width: var(--width-7);
}
.w8 {
  width: var(--width-8);
}

/* We aren't overriding these so we just port them directly from tachyons */
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-33 {
  width: 33%;
}
.w-34 {
  width: 34%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}

@media screen and (min-width: 30em) {
  .w1-ns {
    width: var(--width-1);
  }
  .w2-ns {
    width: var(--width-2);
  }
  .w3-ns {
    width: var(--width-3);
  }
  .w4-ns {
    width: var(--width-4);
  }
  .w5-ns {
    width: var(--width-5);
  }
  .w6-ns {
    width: var(--width-6);
  }
  .w-10-ns {
    width: 10%;
  }
  .w-20-ns {
    width: 20%;
  }
  .w-25-ns {
    width: 25%;
  }
  .w-30-ns {
    width: 30%;
  }
  .w-33-ns {
    width: 33%;
  }
  .w-34-ns {
    width: 34%;
  }
  .w-40-ns {
    width: 40%;
  }
  .w-50-ns {
    width: 50%;
  }
  .w-60-ns {
    width: 60%;
  }
  .w-70-ns {
    width: 70%;
  }
  .w-75-ns {
    width: 75%;
  }
  .w-80-ns {
    width: 80%;
  }
  .w-90-ns {
    width: 90%;
  }
  .w-100-ns {
    width: 100%;
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .w1-m {
    width: var(--width-1);
  }
  .w2-m {
    width: var(--width-2);
  }
  .w3-m {
    width: var(--width-3);
  }
  .w4-m {
    width: var(--width-4);
  }
  .w5-m {
    width: var(--width-5);
  }
  .w6-m {
    width: var(--width-6);
  }
  .w-10-m {
    width: 10%;
  }
  .w-20-m {
    width: 20%;
  }
  .w-25-m {
    width: 25%;
  }
  .w-30-m {
    width: 30%;
  }
  .w-33-m {
    width: 33%;
  }
  .w-34-m {
    width: 34%;
  }
  .w-40-m {
    width: 40%;
  }
  .w-50-m {
    width: 50%;
  }
  .w-60-m {
    width: 60%;
  }
  .w-70-m {
    width: 70%;
  }
  .w-75-m {
    width: 75%;
  }
  .w-80-m {
    width: 80%;
  }
  .w-90-m {
    width: 90%;
  }
  .w-100-m {
    width: 100%;
  }
}

@media screen and (min-width: 60em) {
  .w1-l {
    width: var(--width-1);
  }
  .w2-l {
    width: var(--width-2);
  }
  .w3-l {
    width: var(--width-3);
  }
  .w4-l {
    width: var(--width-4);
  }
  .w5-l {
    width: var(--width-5);
  }
  .w6-l {
    width: var(--width-6);
  }
  .w-10-l {
    width: 10%;
  }
  .w-20-l {
    width: 20%;
  }
  .w-25-l {
    width: 25%;
  }
  .w-30-l {
    width: 30%;
  }
  .w-33-l {
    width: 33%;
  }
  .w-34-l {
    width: 34%;
  }
  .w-40-l {
    width: 40%;
  }
  .w-50-l {
    width: 50%;
  }
  .w-60-l {
    width: 60%;
  }
  .w-70-l {
    width: 70%;
  }
  .w-75-l {
    width: 75%;
  }
  .w-80-l {
    width: 80%;
  }
  .w-90-l {
    width: 90%;
  }
  .w-100-l {
    width: 100%;
  }
}

/* Max Width */

.mw-50 {
  max-width: 50%;
}

/* We aren't overriding this class so we just port it directly from tachyons */
.mw-100 {
  max-width: 100%;
}

.mw1 {
  max-width: var(--max-width-1);
}
.mw2 {
  max-width: var(--max-width-2);
}
.mw3 {
  max-width: var(--max-width-3);
}
.mw4 {
  max-width: var(--max-width-4);
}
.mw5 {
  max-width: var(--max-width-5);
}
.mw6 {
  max-width: var(--max-width-6);
}
.mw7 {
  max-width: var(--max-width-7);
}
.mw8 {
  max-width: var(--max-width-8);
}
.mw9 {
  max-width: var(--max-width-9);
}

@media screen and (min-width: 30em) {
  .mw-100-ns {
    max-width: 100%;
  }

  .mw1-ns {
    max-width: var(--max-width-1);
  }
  .mw2-ns {
    max-width: var(--max-width-2);
  }
  .mw3-ns {
    max-width: var(--max-width-3);
  }
  .mw4-ns {
    max-width: var(--max-width-4);
  }
  .mw5-ns {
    max-width: var(--max-width-5);
  }
  .mw6-ns {
    max-width: var(--max-width-6);
  }
  .mw7-ns {
    max-width: var(--max-width-7);
  }
  .mw8-ns {
    max-width: var(--max-width-8);
  }
  .mw9-ns {
    max-width: var(--max-width-9);
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .mw-100-m {
    max-width: 100%;
  }

  .mw1-m {
    max-width: var(--max-width-1);
  }
  .mw2-m {
    max-width: var(--max-width-2);
  }
  .mw3-m {
    max-width: var(--max-width-3);
  }
  .mw4-m {
    max-width: var(--max-width-4);
  }
  .mw5-m {
    max-width: var(--max-width-5);
  }
  .mw6-m {
    max-width: var(--max-width-6);
  }
  .mw7-m {
    max-width: var(--max-width-7);
  }
  .mw8-m {
    max-width: var(--max-width-8);
  }
  .mw9-m {
    max-width: var(--max-width-9);
  }
}

@media screen and (min-width: 60em) {
  .mw-50-l {
    max-width: 50%;
  }

  .mw-100-l {
    max-width: 100%;
  }

  .mw1-l {
    max-width: var(--max-width-1);
  }
  .mw2-l {
    max-width: var(--max-width-2);
  }
  .mw3-l {
    max-width: var(--max-width-3);
  }
  .mw4-l {
    max-width: var(--max-width-4);
  }
  .mw5-l {
    max-width: var(--max-width-5);
  }
  .mw6-l {
    max-width: var(--max-width-6);
  }
  .mw7-l {
    max-width: var(--max-width-7);
  }
  .mw8-l {
    max-width: var(--max-width-8);
  }
  .mw9-l {
    max-width: var(--max-width-9);
  }
}
