/* Background colors */

.bg-ink {
  background-color: var(--ink);
}
.bg-light-ink {
  background-color: var(--ink-light);
}
.bg-washed-ink {
  background-color: var(--ink-washed);
}
.bg-ink-90 {
  background-color: var(--ink-90);
}
.bg-ink-80 {
  background-color: var(--ink-80);
}
.bg-ink-70 {
  background-color: var(--ink-70);
}
.bg-ink-60 {
  background-color: var(--ink-60);
}
.bg-ink-50 {
  background-color: var(--ink-50);
}
.bg-ink-40 {
  background-color: var(--ink-40);
}
.bg-ink-30 {
  background-color: var(--ink-30);
}
.bg-ink-20 {
  background-color: var(--ink-20);
}
.bg-ink-10 {
  background-color: var(--ink-10);
}

.bg-compass-green {
  background-color: var(--compass-green);
}

.bg-mint {
  background-color: var(--mint);
}
.bg-light-mint {
  background-color: var(--mint-light);
}
.bg-washed-mint {
  background-color: var(--mint-washed);
}

.bg-indigo {
  background-color: var(--indigo);
}
.bg-light-indigo {
  background-color: var(--indigo-light);
}
.bg-washed-indigo {
  background-color: var(--indigo-washed);
}

.bg-orange {
  background-color: var(--orange);
}
.bg-light-orange {
  background-color: var(--orange-light);
}
.bg-washed-orange {
  background-color: var(--orange-washed);
}

.bg-washed-purple {
  background-color: var(--purple-washed);
}

.bg-pink {
  background-color: var(--pink);
}
.bg-pink-light {
  background-color: var(--pink-light);
}
.bg-washed-pink {
  background-color: var(--pink-washed);
}

.bg-haze {
  background-color: var(--haze);
}
.bg-haze-light {
  background-color: var(--haze-light);
}
.bg-washed-haze {
  background-color: var(--haze-washed);
}

.bg-haze-lightest {
  background-color: var(--haze-lightest);
}
.bg-pine {
  background-color: var(--pine);
}
.bg-teal-washed {
  background-color: var(--teal-washed);
}
.bg-yellow {
  background-color: var(--yellow);
}

.bg-grey {
  background-color: var(--grey);
}
.bg-grey-washed {
  background-color: var(--grey-washed);
}
.grey-lightest {
  color: var(--grey-lightest);
}

.bg-none {
  background-color: transparent !important;
  background-image: none !important;
}

/* Text colors */

.ink {
  color: var(--ink);
}
.light-ink {
  color: var(--ink-light);
}
.washed-ink {
  color: var(--ink-washed);
}
.ink-90 {
  color: var(--ink-90);
}
.ink-80 {
  color: var(--ink-80);
}
.ink-70 {
  color: var(--ink-70);
}
.ink-60 {
  color: var(--ink-60);
}
.ink-50 {
  color: var(--ink-50);
}
.ink-40 {
  color: var(--ink-40);
}
.ink-30 {
  color: var(--ink-30);
}
.ink-20 {
  color: var(--ink-20);
}
.ink-10 {
  color: var(--ink-10);
}
.ink-05 {
  color: var(--ink-05);
}
.compass-green {
  color: var(--compass-green);
}
.green {
  color: var(--green);
}
.mint {
  color: var(--mint);
}
.light-mint {
  color: var(--mint-light);
}
.washed-mint {
  color: var(--mint-washed);
}
.blue {
  color: var(--blue)
}
.indigo {
  color: var(--indigo);
}
.light-indigo {
  color: var(--indigo-light);
}
.washed-indigo {
  color: var(--indigo-washed);
}
.orange {
  color: var(--orange);
}
.light-orange {
  color: var(--orange-light);
}
.washed-orange {
  color: var(--orange-washed);
}
.purple {
  color: var(--purple);
}
.washed-purple {
  color: var(--purple-washed);
}

.pink {
  color: var(--pink);
}
.pink-light {
  color: var(--pink-light);
}
.washed-pink {
  color: var(--pink-washed);
}

.haze {
  color: var(--haze);
}
.haze-light {
  color: var(--haze-light);
}
.washed-haze {
  color: var(--haze-washed);
}
.mustard {
  color: var(--mustard);
}
.ink-light {
  color: var(--ink-light);
}

/* Opacity */

.opacity-80 {
  opacity: 80%;
}

/* Pseudo-classes */

.hover-indigo:hover,
.hover-indigo:focus {
  color: var(--indigo);
}

/* Media queries */

@media screen and (min-width: 30em) {
  .near-white-ns {
    color: var(--near-white);
  }
  .ink-ns {
    color: var(--ink);
  }

  .red-ns {
    color: var(--red);
  }
  .red-light-ns {
    color: var(---red-light);
  }
  .orange-ns {
    color: var(--orange);
  }
  .yellow-ns {
    color: var(--yellow);
  }
  .yellow-light-ns {
    color: var(--yellow-light);
  }
  .purple-ns {
    color: var(--purple);
  }
  .purple-light-ns {
    color: var(--purple-light);
  }
  .pink-ns {
    color: var(--pink);
  }
  .pink-light-ns {
    color: var(--pink-light);
  }
  .green-ns {
    color: var(--green);
  }
  .green-light-ns {
    color: var(--green-light);
  }
  .blue-ns {
    color: var(--blue);
  }
  .blue-light-ns {
    color: var(--blue-light);
  }
  .blue-lightest-ns {
    color: var(--blue-lightest);
  }
  .washed-blue-ns {
    color: var(--blue-washed);
  }
  .washed-green-ns {
    color: var(--green-washed);
  }
  .washed-yellow-ns {
    color: var(--yellow-washed);
  }
  .washed-red-ns {
    color: var(--red-washed);
  }
  .color-inherit-ns {
    color: inherit;
  }

  /* Background colors */

  .bg-near-white-ns {
    background-color: var(--near-white);
  }

  .bg-haze-ns {
    background-color: var(--haze);
  }
  .bg-haze-light-ns {
    background-color: var(--haze-light);
  }
  .bg-washed-haze-ns {
    background-color: var(--haze-washed);
  }
  .bg-red-ns {
    background-color: var(--red);
  }
  .bg-red-light-ns {
    background-color: var(--red-light);
  }
  .bg-orange-ns {
    background-color: var(--orange);
  }
  .bg-yellow-ns {
    background-color: var(--yellow);
  }
  .bg-yellow-light-ns {
    background-color: var(--yellow-light);
  }
  .bg-purple-ns {
    background-color: var(--purple);
  }
  .bg-purple-light-ns {
    background-color: var(--purple-light);
  }
  .bg-pink-ns {
    background-color: var(--pink);
  }
  .bg-pink-light-ns {
    background-color: var(--pink-light);
  }
  .bg-green-ns {
    background-color: var(--green);
  }
  .bg-green-light-ns {
    background-color: var(--green-light);
  }
  .bg-blue-ns {
    background-color: var(--blue);
  }
  .bg-blue-light-ns {
    background-color: var(--blue-light);
  }
  .bg-blue-lightest-ns {
    background-color: var(--blue-lightest);
  }
  .bg-washed-blue-ns {
    background-color: var(--blue-washed);
  }
  .bg-washed-green-ns {
    background-color: var(--green-washed);
  }
  .bg-washed-yellow-ns {
    background-color: var(--yellow-washed);
  }
  .bg-washed-red-ns {
    background-color: var(--red-washed);
  }
  .bg-inherit-ns {
    background-color: inherit;
  }
}

@media screen and (min-width: 60em) {
  .near-white-l {
    color: var(--near-white);
  }
  .ink-l {
    color: var(--ink);
  }
  .red-l {
    color: var(--red);
  }
  .red-light-l {
    color: var(--red-light);
  }
  .orange-l {
    color: var(--orange);
  }
  .yellow-l {
    color: var(--yellow);
  }
  .yellow-light-l {
    color: var(--yellow-light);
  }
  .purple-l {
    color: var(--purple);
  }
  .purple-light-l {
    color: var(--purple-light);
  }
  .pink-l {
    color: var(--pink);
  }
  .pink-light-l {
    color: var(--pink-light);
  }
  .green-l {
    color: var(--green);
  }
  .blue-l {
    color: var(--blue);
  }
  .blue-light-l {
    color: var(--blue-light);
  }
  .blue-lightest-l {
    color: var(--blue-lightest);
  }
  .washed-blue-l {
    color: var(--blue-washed);
  }
  .washed-green-l {
    color: var(--green-washed);
  }
  .washed-yellow-l {
    color: var(--yellow-washed);
  }
  .washed-red-l {
    color: var(--red-washed);
  }
  .color-inherit-l {
    color: inherit;
  }

  /* Background colors */

  .bg-haze-l {
    background-color: var(--haze);
  }
  .bg-haze-light-l {
    background-color: var(--haze-light);
  }
  .bg-washed-haze-l {
    background-color: var(--haze-washed);
  }
  .bg-red-l {
    background-color: var(--red);
  }
  .bg-red-light-l {
    background-color: var(--red-light);
  }
  .bg-orange-l {
    background-color: var(--orange);
  }
  .bg-yellow-l {
    background-color: var(--yellow);
  }
  .bg-yellow-l {
    background-color: var(--yellow);
  }
  .bg-yellow-light-l {
    background-color: var(--yellow-light);
  }
  .bg-purple-l {
    background-color: var(--purple);
  }
  .bg-purple-light-l {
    background-color: var(--purple-light);
  }
  .bg-pink-l {
    background-color: var(--pink);
  }
  .bg-pink-light-l {
    background-color: var(--pink-light);
  }
  .bg-green-l {
    background-color: var(--green);
  }
  .bg-green-light-l {
    background-color: var(--green-light);
  }
  .bg-blue-l {
    background-color: var(--blue);
  }
  .bg-blue-light-l {
    background-color: var(--blue-light);
  }
  .bg-blue-lightest-l {
    background-color: var(--blue-lightest);
  }
  .bg-washed-blue-l {
    background-color: var(--blue-washed);
  }
  .bg-washed-green-l {
    background-color: var(--green-washed-green);
  }
  .bg-washed-yellow-l {
    background-color: var(--yellow-washed);
  }
  .bg-washed-red-l {
    background-color: var(--red-washed);
  }
  .bg-inherit-l {
    background-color: inherit;
  }
}
