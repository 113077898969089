@import '~tachyons/css/tachyons.min.css';
@import '../../css/tachyons-extensions/index.css';
@import '../../css//overrides/index.css';
/**
 * Added to make the bottom chrome on ios safari visible all the time because
 * of the "dead zone" that safari adds.
 *
 * https://www.eventbrite.com/engineering/mobile-safari-why/
 */
html,
body {
  height: 100%;
  font-size: 16px;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}
body {
  overflow-y: scroll;
}

/* Temp Compass Overrides - Till we bump to the version that fixes it */

/* Fixed in compass v0.6.3 - will need to remove wrapping divs that use this class */
.trailing-icon-input svg {
  cursor: pointer;
}

.trailing-icon-input.disabled svg {
  cursor: not-allowed;
}

.btn-no-padding button {
  padding: 0;
}
