@import '../../../css/variables/index.css';

.ReactModal__Content.compass-modal-content {
  max-height: 100vh;
}

.ReactModal__Content > .compass-modal-body > :not(#loading):last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.ReactModal__Overlay.compass-modal-overlay {
  inset: 0;
  position: fixed;
}
