.faq-table {
  border-style: solid;
  border-width: 1px;
  border-color: #c6c5c6;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-radius: 8px;
  background-color: var(--haze-lightest);
  width: 100%;
}

.faq-table-cell-left {
  padding: var(--spacing-medium);
  border-bottom: 1px solid #c6c5c6;
  border-right: 1px solid #c6c5c6;
}

.faq-table-cell-right {
  padding: var(--spacing-medium);
  border-bottom: 1px solid #c6c5c6;
}

.faq-table-cell-bottom-left {
  padding: var(--spacing-medium);
  border-right: 1px solid #c6c5c6;
}

.faq-table-cell-bottom-right {
  padding: var(--spacing-medium);
}
