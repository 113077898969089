.form-input-gap {
  gap: 1.5rem;
}

.input-max-width-column {
  width: 90%;
}

.monthly-living-warning-column {
  width: 90%;
}

.input-max-width-row {
  width: 339px;
}

@media only screen and (max-width: 1200px) {
  .input-max-width-row {
    width: 100%;
  }

  .input-max-width-column {
    width: 100%;
  }

  .monthly-living-warning-column {
    width: 100%;
  }
}

.income-update-form__money-input-wrapper {
  white-space: nowrap;
}
