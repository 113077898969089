@media only screen and (min-width: 767px) {
  .container {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .icon-container {
    margin-right: 0;
    display: flex;
    justify-content: center;
  }
}

