@media print {
  body {
    visibility: hidden;
  }
  .eftaText {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}
