.edit-financial-info--desktop-image-container {
  min-width: 250px;
}

.edit-financial-info--desktop-container {
  min-height: 459px;
  max-width: 744px;
  margin-left: auto;
  margin-right: auto;
}

.edit-financial-info--mobile-image-container {
  display: none;
}

.form-input-gap {
  gap: 0.75rem;
}

@media only screen and (max-width: 600px) {
  .edit-financial-info--desktop-image-container {
    display: none !important;
  }

  .edit-financial-info--mobile-image-container {
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0.875rem;
  }

  .edit-financial-info--mobile-image {
    width: 5rem;
  }
}
