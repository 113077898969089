@import '../variables/index.css';

/* 

Font Faces 

NOTE - These font faces are deprecated, we should be using compass typography components going forward, 
which already use these fonts internally. Once we can replace all regular typography elements 
that use these fonts with compass, these should be safe to remove.

*/

@font-face {
  font-family: 'Suisse-Regular';
  src: url('../../assets/fonts/Suisse/SuisseIntl-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Bold';
  src: url('../../assets/fonts/Suisse/SuisseIntl-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Bold-Italic';
  src: url('../../assets/fonts/Suisse/SuisseIntl-BoldItalic.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Book';
  src: url('../../assets/fonts/Suisse/SuisseIntl-Book.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Book-Italic';
  src: url('../../assets/fonts/Suisse/SuisseIntl-BookItalic.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Light';
  src: url('../../assets/fonts/Suisse/SuisseIntl-Light.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Medium';
  src: url('../../assets/fonts/Suisse/SuisseIntl-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Medium-Italic';
  src: url('../../assets/fonts/Suisse/SuisseIntl-MediumItalic.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Semibold';
  src: url('../../assets/fonts/Suisse/SuisseIntl-SemiBold.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Semibold-Italic';
  src: url('../../assets/fonts/Suisse/SuisseIntl-SemiBoldItalic.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Thin-Italic';
  src: url('../../assets/fonts/Suisse/SuisseIntl-ThinItalic.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Mono';
  src: url('../../assets/fonts/Suisse/SuisseIntlMono-Regular.otf')
    format('truetype');
}

@font-face {
  font-family: 'Suisse-Mono-Bold';
  src: url('../../assets/fonts/Suisse/SuisseIntlMono-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Suisse-Mono-Thin';
  src: url('../../assets/fonts/Suisse/SuisseIntlMono-Thin.otf') format('truetype');
}

/* Font size */

.f8 { font-size: var(--font-size-8); }
.f9 { font-size: var(--font-size-9); }

@media screen and (min-width: 30em) {
  .f8-ns { font-size: var(--font-size-8); }
  .f9-ns { font-size: var(--font-size-9); }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .f8-m { font-size: var(--font-size-8); }
  .f9-m { font-size: var(--font-size-9); }
}

@media screen and (min-width: 60em) {
  .f8-l { font-size: var(--font-size-8); }
  .f9-l { font-size: var(--font-size-9); }
}
