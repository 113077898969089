@import '../../../../css/variables/index.css';

/* Achieves a masonry layout */
.existing-offer-dashboard__container {
    display: flex;
    flex-direction: column;

    & > div {
      margin-bottom: var(--spacing-extra-medium);
    }
}

.existing-offer-dashboard__cancel-plan-button * {
  color: var(--red);
  padding-left: 0;
}

@media screen and (min-width: 1200px) {
  .existing-offer-dashboard__container {
    /* max height ensures only two cards per row in the column */
    max-height: 600px;
    flex-wrap: wrap;
    gap: var(--spacing-extra-medium);

    & > div {
      margin-bottom: 0;
      width: calc(50% - (var(--spacing-extra-medium) / 2));
    }

    & > .funding-account-kard {
      order: 1
    }

    & > .upcoming-payment-kard {
      order: 2
    }
  }
}
