@import '../variables/index.css';

/* Transition duration */

.td-1 { transition-duration: 120ms; }
.td-2 { transition-duration: .3s; }
.td-3 { transition-duration: .6s; }
.td-4 { transition-duration: 1s; }
.td-5 { transition-duration: 5s; }
.td-i { transition-duration: inherit; }

@media screen and (min-width: 30em) {
  .td-1-ns { transition-duration: 120ms; }
  .td-2-ns { transition-duration: .3s; }
  .td-3-ns { transition-duration: .6s; }
  .td-4-ns { transition-duration: 1s; }
  .td-5-ns { transition-duration: 5s; }
  .td-i-ns { transition-duration: inherit; }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .td-1-m { transition-duration: 120ms; }
  .td-2-m { transition-duration: .3s; }
  .td-3-m { transition-duration: .6s; }
  .td-4-m { transition-duration: 1s; }
  .td-5-m { transition-duration: 5s; }
  .td-i-m { transition-duration: inherit; }
}

@media screen and (min-width: 60em) {
  .td-1-l { transition-duration: 120ms; }
  .td-2-l { transition-duration: .3s; }
  .td-3-l { transition-duration: .6s; }
  .td-4-l { transition-duration: 1s; }
  .td-5-l { transition-duration: 5s; }
  .td-i-l { transition-duration: inherit; }
}

/* Transition property */

.tp-n   { transition-property: none; }
.tp-all { transition-property: all; }
.tp-i   { transition-property: inherit; }
.tp-c   { transition-property: color; }
.tp-bc  { transition-property: background-color; }
.tp-w   { transition-property: width; }
.tp-h   { transition-property: height; }
.tp-l   { transition-property: left; }
.tp-r   { transition-property: right; }
.tp-t   { transition-property: top; }
.tp-b   { transition-property: bottom; }
.tp-o   { transition-property: opacity; }

@media screen and (min-width: 30em) {
  .tp-n-ns   { transition-property: none; }
  .tp-all-ns { transition-property: all; }
  .tp-i-ns   { transition-property: inherit; }
  .tp-c-ns   { transition-property: color; }
  .tp-bc-ns  { transition-property: background-color; }
  .tp-w-ns   { transition-property: width; }
  .tp-h-ns   { transition-property: height; }
  .tp-l-ns   { transition-property: left; }
  .tp-r-ns   { transition-property: right; }
  .tp-t-ns   { transition-property: top; }
  .tp-b-ns   { transition-property: bottom; }
  .tp-o-ns   { transition-property: opacity; }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .tp-n-m   { transition-property: none; }
  .tp-all-m { transition-property: all; }
  .tp-i-m   { transition-property: inherit; }
  .tp-c-m   { transition-property: color; }
  .tp-bc-m  { transition-property: background-color; }
  .tp-w-m   { transition-property: width; }
  .tp-h-m   { transition-property: height; }
  .tp-l-m   { transition-property: left; }
  .tp-r-m   { transition-property: right; }
  .tp-t-m   { transition-property: top; }
  .tp-b-m   { transition-property: bottom; }
  .tp-o-m   { transition-property: opacity; }
}

@media screen and (min-width: 60em) {
  .tp-n-l   { transition-property: none; }
  .tp-all-l { transition-property: all; }
  .tp-i-l   { transition-property: inherit; }
  .tp-c-l   { transition-property: color; }
  .tp-bc-l  { transition-property: background-color; }
  .tp-w-l   { transition-property: width; }
  .tp-h-l   { transition-property: height; }
  .tp-l-l   { transition-property: left; }
  .tp-r-l   { transition-property: right; }
  .tp-t-l   { transition-property: top; }
  .tp-b-l   { transition-property: bottom; }
  .tp-o-l   { transition-property: opacity; }
}
