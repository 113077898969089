:root {
  /* Border radius */
  --border-radius-none: 0;
  --border-radius-1: 0.125rem;
  --border-radius-2: 0.25rem;
  --border-radius-3: 0.5rem;
  --border-radius-4: 1rem;
  --border-radius-circle: 100%;
  --border-radius-pill: 9999px;

  /* Border width */
  --border-width-none: 0;
  --border-width-1: 0.125rem;
  --border-width-2: 0.25rem;
  --border-width-3: 0.5rem;
  --border-width-4: 1rem;
  --border-width-5: 2rem;
}