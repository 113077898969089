.PayWithCard .hidden {
  visibility: hidden;
  top: 0;
  display: none;
}

.PayWithCard .submittingIframe {
  display: flex;
  position: relative;
  overflow: visible;
  top: -115%;
  height: 50vh;
}
