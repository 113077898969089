.good-standing-tips-container {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.good-standing-accordian-label {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.good-standing-label-container {
  display: flex;
  padding: 0;
  align-items: center;
  gap: 1rem;
}

.good-standing-tips-button,
.good-standing-tips-button:focus,
.good-standing-tips-button:active {
  outline: none;
  background: transparent;
  border: 1px solid #cbd8d6;
  border-radius: 8px;
  display: flex;
  padding: 1rem;
  flex-shrink: 0;
  margin-bottom: 0.5rem;
  flex-direction: column;
  text-align: start;
}

.good-standing-tips-expanded-container {
  margin: 1rem 0 0.5rem 0;
}
