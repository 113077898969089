.hr-border {
  border-top: 1.25px solid #CBD8D6;
}

.expand-btn-container { 
  margin-left: auto; 
  margin-top: .75rem ;
}

.detail-text {
  margin-left: 1.5rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
}