.desktop-image-container {
  min-width: 250px;
}

.mobile-text-container {
  flex: 0 1 65%;
}

.mobile-image-container {
  flex: 1 1 32%;
}

.mobile-text-image-gap {
  gap: 1.5rem;
}

.desktop-container {
  min-height: 317px;
}

.success-description-container {
  max-width: 327px;
  text-align: center;
}

.income-update-banner__button-container--mobile {
  flex-direction: column;
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
}
