@import '../variables/index.css';

/* Border colors */

.b--ink {
  border-color: var(--ink);
}
.b--ink-90 {
  border-color: var(--ink-90);
}
.b--ink-80 {
  border-color: var(--ink-80);
}
.b--ink-70 {
  border-color: var(--ink-70);
}
.b--ink-60 {
  border-color: var(--ink-60);
}
.b--ink-50 {
  border-color: var(--ink-50);
}
.b--ink-40 {
  border-color: var(--ink-40);
}
.b--ink-30 {
  border-color: var(--ink-30);
}
.b--ink-20 {
  border-color: var(--ink-20);
}
.b--ink-10 {
  border-color: var(--ink-10);
}
.b--ink-05 {
  border-color: var(--ink-05);
}
.b--ink-025 {
  border-color: var(--ink-025);
}
.b--ink-0125 {
  border-color: var(--ink-0125);
}

.b--compass-green {
  border-color: var(--compass-green);
}

.b--mint {
  border-color: var(--mint);
}
.b--light-mint {
  border-color: var(--mint-light);
}
.b--washed-mint {
  border-color: var(--mint-washed);
}

.b--indigo {
  border-color: var(--indigo);
}
.b--light-indigo {
  border-color: var(--indigo-light);
}
.b--washed-indigo {
  border-color: var(--indigo-washed);
}

.b--ink {
  border-color: var(--ink);
}
.b--light-ink {
  border-color: var(--ink-light);
}
.b--washed-ink {
  border-color: var(--ink-washed);
}

.b--orange {
  border-color: var(--orange);
}
.b--light-orange {
  border-color: var(--orange-light);
}
.b--washed-orange {
  border-color: var(--orange-washed);
}

.b--washed-purple {
  border-color: var(--purple-washed);
}

.b--pink {
  border-color: var(--pink);
}
.b--light-pink {
  border-color: var(--pink-light);
}
.b--washed-pink {
  border-color: var(--pink-washed);
}

.b--haze {
  border-color: var(--haze);
}

.b--grey-lightest {
  border-color: var(--grey-lightest);
}

/* Border width */
.bw--1px {
  border-width: 1px;
}

.bw--1-5px {
  border-width: 1.5px;
}
