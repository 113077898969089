:root {
  --height-1: 1rem;
  --height-2: 2rem;
  --height-3: 4rem;
  --height-4: 8rem;
  --height-5: 16rem;

  --width-1: 1rem;
  --width-2: 2rem;
  --width-3: 4rem;
  --width-4: 8rem;
  --width-5: 16rem;
  --width-6: 32rem;
  --width-7: 48rem;
  --width-8: 64rem;

  --max-width-1: 1rem;
  --max-width-2: 2rem;
  --max-width-3: 4rem;
  --max-width-4: 8rem;
  --max-width-5: 16rem;
  --max-width-6: 32rem;
  --max-width-7: 48rem;
  --max-width-8: 64rem;
  --max-width-9: 80rem;
}
