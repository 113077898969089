@import '@core/css/variables/index.css';

.transaction-filter-menu__trigger,
.transaction-search__action-btn {
  cursor: pointer;
  background: none;
  border: none;
  padding: 1px;
  border-radius: 50%;
  /* margins below account for the difference between the 
  height of the search input and these bubble icons */
  margin-top: 8.5px;
  margin-bottom: 8.5px;
}

.transaction-filter-menu__content {
  background-color: white;
  border-radius: 4px;
  /* Elevation-1 */
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}

.transaction-filter-menu__content--item {
  padding: 0.5rem 1.5rem;
  cursor: pointer;
}

.transaction-filter-menu__content--item[data-highlighted] {
  background-color: var(--blue-washed);
}

.transaction-filter-menu__content--item.selected {
  background-color: var(--indigo-washed);
}
