:root {
  /* Ink */

  /*--- 
  Ink is used for copy. The primary shade is used for headers and bolded copy,
  while the tint shade is used for body text.
  ---*/
  --ink: #122c26;
  --ink-light: #677370;
  --ink-lightest: #cbd8d6;
  --ink-washed: #dbdbde;
  --ink-90: rgba(13, 11, 35, 0.9);
  --ink-80: rgba(13, 11, 35, 0.8);
  --ink-70: rgba(13, 11, 35, 0.7);
  --ink-60: rgba(13, 11, 35, 0.6);
  --ink-50: rgba(13, 11, 35, 0.5);
  --ink-40: rgba(13, 11, 35, 0.4);
  --ink-30: rgba(13, 11, 35, 0.3);
  --ink-20: rgba(13, 11, 35, 0.2);
  --ink-10: rgba(13, 11, 35, 0.1);
  --ink-05: rgba(13, 11, 35, 0.05);
  --ink-025: rgba(13, 11, 35, 0.025);
  --ink-0125: rgba(13, 11, 35, 0.0125);

  /* Compass green */

  --compass-green: #007459;

  /*--- 
  Green is our main brand color. It is tied to a predominant amount 
  of our promotional materials, landing pages, and our credit card product. 
  It is also used to demonstrate “positive” interactions, highlight money, 
  and financial data.  
  ---*/
  --green: var(--compass-green);
  --green-light: #60cda9;
  --green-lightest: #b5e5da;
  --green-washed: #d9f2e8;

  --mint: #82dec5;
  --mint-light: #cdf2e8;
  --mint-washed: #edfaf7;

  /* Blue */

  /*---
  Blue is only used for “informative” actionable components and links.
  It is not to be used for anything else. 
  ---*/
  --blue: #005cc4;
  --blue-light: #65a8ff;
  --blue-lightest: #b8d3f4;
  --blue-washed: #dbf2ff;

  /* Indigo */

  /*---
  Indigo is used for CTA hover states. It is also the primary branding 
  color for Mission Money.
  ---*/
  --indigo: #032c68;
  --indigo-light: #5b82b8;
  --indigo-lightest: #a5caff;
  --indigo-washed: #e4efff;

  /* Red */

  /*-- 
  Red is only used to denote errors, critical warnings, and “negative” states and copy. 
  ---*/
  --red: #b50b13;
  --red-light: #cb545a;
  --red-lightest: #e9b6b8;
  --red-washed: #f8e7e7;

  /* Mustard */

  /*--- 
  Mustard is only used to denote uncritical “notice” or uncritical warnings states.
  ---*/
  --mustard: #8f5907;
  --mustard-light: #caa852;
  --mustard-lightest: #e8dab5;
  --mustard-washed: #fef1c7;

  /* Grey */

  /*--- 
  Grey is only used for “disabled” states for components.
  ---*/
  --grey: #535353;
  --grey-light: #898989;
  --grey-lightest: #d6dad7;
  --grey-washed: #ececec;

  /* Pine */

  /*--- 
  Pine is one our secondary brand colors. It is used in a lot of branding backgrounds 
  complimentary to Green. 
  ---*/
  --pine: #072d26;
  --pine-light: #64958b;
  --pine-lightest: #bad2cd;
  --pine-washed: #c5e7e0;

  /* Brick */

  /*--- 
  Brick is one of our secondary brand colors. It is predominantly tied to Mission Lane 
  Earn and its respective branding.
  ---*/
  --brick: #c44a17;
  --brick-light: #ff9987;
  --brick-lightest: #fcd4c2;
  --brick-washed: #fff8f4;

  /* Orange */

  /*---
  Orange is one of our tertiary brand colors. It is only used in illustration. 
  ---*/
  --orange: #f98638;
  --orange-light: #ffa86e;
  --orange-lightest: #fadec9;
  --orange-washed: #fef3eb;

  /* Purple */

  /*--- 
  Purple is one of our secondary brand colors. It may be tied to a future product 
  and its respective branding. 
  ---*/
  --purple: #6b297d;
  --purple-light: #a778b1;
  --purple-lightest: #eec7f8;
  --purple-washed: #edd6f2;

  /* Teal */

  /*---
  Teal is one of our secondary brand colors. It may be tied to a future product
  and its respective branding. 
  ---*/
  --teal: #025c62;
  --teal-light: #59bac0;
  --teal-lightest: #bffbff;
  --teal-washed: #f3fafa;

  /* Pink */

  --pink: #ff9c86;
  --pink-light: #ffd7cf;
  --pink-washed: #fff0ed;

  /* Yellow */

  /*---
  Yellow is one our secondary brand colors. It is used in a lot of branding complimentary to mustard.
  ---*/
  --yellow: #f8ba35;
  --yellow-light: #f7d479;
  --yellow-lightest: #f7e8c1;
  --yellow-washed: #fef9ec;

  /* Haze */

  /*---
  Haze is one of our tertiary brand colors. It is used primarily as background color. 
  ---*/
  --haze: #e8dfda;
  --haze-light: #f5f2eb;
  --haze-lightest: #f9f8f7;
  --haze-washed: #ffffff;

  --near-white: --light-haze;
}
