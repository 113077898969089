.bank-check {
  position: relative;
  height: 72px;
  padding: 0 5px 5px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.27),
    inset 0 0 40px rgba(0, 0, 0, 0.06);
}

.bank-check-boarders {
  background: none;
  border: 3px double #d0dde0;
  height: 100%;
  border-top: 0;
  padding: 10px;
}

.bank-check-fade {
  height: 105%;
  position: absolute;
  width: 100%;
  z-index: 1;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#dee8e0+0,f6f6f4+100&0+1,1+100 */
  background: -moz-linear-gradient(
    left,
    rgba(222, 232, 224, 0) 0%,
    rgba(222, 232, 224, 0) 1%,
    rgba(246, 246, 244, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(222, 232, 224, 0) 0%,
    rgba(222, 232, 224, 0) 1%,
    rgba(246, 246, 244, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(222, 232, 224, 0) 0%,
    rgba(222, 232, 224, 0) 1%,
    rgba(246, 246, 244, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00dee8e0', endColorstr='#f6f6f4',GradientType=1 ); /* IE6-9 */
}

.bank-check-numbers {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  margin-top: 10px;
}

.bank-check-line {
  height: 30px;
  border-left: 1px solid #c3c3c3;
  width: 0;
  position: absolute;
  top: 60px;
  left: 34px;
}

.bank-check-line.bank-check-account-edit {
  left: 152px;
}

.bank-check-routing,
.bank-check-account-routing {
  display: inline-block;
}

.bank-check-routing,
.bank-check-account-routing {
  width: 105px;
}

.bank-check-account-routing {
  width: 120px;
}

.bank-check-account {
  transition: opacity 0.15s ease-in;
}

.bank-check-routing:before {
  margin-right: 0.25rem;
  content: url('./check_routing_thingy.png');
}

.bank-check-account-routing:before {
  margin-right: 0.25rem;
  content: url('./check_routing_thingy.png') '\00a0'url('./check_account_thingy.png');
}

.bank-check-account:before {
  margin-right: 0.25rem;
  content: url('./check_account_thingy.png');
}
