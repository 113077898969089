@import '../../../@core/css/variables/index.css';
.make-payment-page-body {
  display: flex;
  gap: 2rem;
}

.make-payment-page-body .upcoming-payments-container {
  flex-flow: column;
  gap: 2rem;
  width: var(--width-6);
}

@media only screen and (max-width: 834px) {
  .make-payment-page-body {
    gap: 1rem;
  }

  .make-payment-page-body .upcoming-payments-container {
    width: 100%;
    gap: 1rem;
  }
}
