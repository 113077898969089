.BankCardField .Error {
  margin-left: 5px;
  display: inline-block;
  color: #b50b13;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.BankCardInputGroup {
  display: flex;
  justify-content: space-between;
}

.BankCardInputGroup .BankCardField {
  width: 100%;
}

.BankCardInputGroup .BankCardField:first-child {
  margin-right: 5px;
}

.BankCardInputGroup .BankCardField:last-child {
  margin-left: 5px;
}

#BankCardDetails iframe {
  width: 100%;
  height: 52px;
}
