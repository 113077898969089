:root {
  --spacing-none: 0;
  --spacing-extra-small: 0.25rem;
  --spacing-small: 0.5rem;
  --spacing-medium: 1rem;
  --spacing-extra-medium: 1.5rem;
  --spacing-large: 2rem;
  --spacing-extra-large: 4rem;
  --spacing-extra-extra-large: 8rem;
  --spacing-extra-extra-extra-large: 16rem;
}
