@import '../variables/index.css';

/* Regular spacing */

.pa0 {
  padding: var(--spacing-none);
}
.pa1 {
  padding: var(--spacing-extra-small);
}
.pa2 {
  padding: var(--spacing-small);
}
.pa3 {
  padding: var(--spacing-medium);
}
.pa4 {
  padding: var(--spacing-extra-medium);
}
.pa5 {
  padding: var(--spacing-large);
}
.pa6 {
  padding: var(--spacing-extra-large);
}
.pa7 {
  padding: var(--spacing-extra-extra-large);
}
.pa8 {
  padding: var(--spacing-extra-extra-extra-large);
}

.pl0 {
  padding-left: var(--spacing-none);
}
.pl1 {
  padding-left: var(--spacing-extra-small);
}
.pl2 {
  padding-left: var(--spacing-small);
}
.pl3 {
  padding-left: var(--spacing-medium);
}
.pl4 {
  padding-left: var(--spacing-extra-medium);
}
.pl5 {
  padding-left: var(--spacing-large);
}
.pl6 {
  padding-left: var(--spacing-extra-large);
}
.pl7 {
  padding-left: var(--spacing-extra-extra-large);
}
.pl8 {
  padding-left: var(--spacing-extra-extra-extra-large);
}

.pr0 {
  padding-right: var(--spacing-none);
}
.pr1 {
  padding-right: var(--spacing-extra-small);
}
.pr2 {
  padding-right: var(--spacing-small);
}
.pr3 {
  padding-right: var(--spacing-medium);
}
.pr4 {
  padding-right: var(--spacing-extra-medium);
}
.pr5 {
  padding-right: var(--spacing-large);
}
.pr6 {
  padding-right: var(--spacing-extra-large);
}
.pr7 {
  padding-right: var(--spacing-extra-extra-large);
}
.pr8 {
  padding-right: var(--spacing-extra-extra-extra-large);
}

.pb0 {
  padding-bottom: var(--spacing-none);
}
.pb1 {
  padding-bottom: var(--spacing-extra-small);
}
.pb2 {
  padding-bottom: var(--spacing-small);
}
.pb3 {
  padding-bottom: var(--spacing-medium);
}
.pb4 {
  padding-bottom: var(--spacing-extra-medium);
}
.pb5 {
  padding-bottom: var(--spacing-large);
}
.pb6 {
  padding-bottom: var(--spacing-extra-large);
}
.pb7 {
  padding-bottom: var(--spacing-extra-extra-large);
}
.pb8 {
  padding-bottom: var(--spacing-extra-extra-extra-large);
}

.pt0 {
  padding-top: var(--spacing-none);
}
.pt1 {
  padding-top: var(--spacing-extra-small);
}
.pt2 {
  padding-top: var(--spacing-small);
}
.pt3 {
  padding-top: var(--spacing-medium);
}
.pt4 {
  padding-top: var(--spacing-extra-medium);
}
.pt5 {
  padding-top: var(--spacing-large);
}
.pt6 {
  padding-top: var(--spacing-extra-large);
}
.pt7 {
  padding-top: var(--spacing-extra-extra-large);
}
.pt9 {
  padding-top: var(--spacing-extra-extra-extra-large);
}

.pv0 {
  padding-top: var(--spacing-none);
  padding-bottom: var(--spacing-none);
}
.pv1 {
  padding-top: var(--spacing-extra-small);
  padding-bottom: var(--spacing-extra-small);
}
.pv2 {
  padding-top: var(--spacing-small);
  padding-bottom: var(--spacing-small);
}
.pv3 {
  padding-top: var(--spacing-medium);
  padding-bottom: var(--spacing-medium);
}
.pv4 {
  padding-top: var(--spacing-extra-medium);
  padding-bottom: var(--spacing-extra-medium);
}
.pv5 {
  padding-top: var(--spacing-large);
  padding-bottom: var(--spacing-large);
}
.pv6 {
  padding-top: var(--spacing-extra-large);
  padding-bottom: var(--spacing-extra-large);
}
.pv7 {
  padding-top: var(--spacing-extra-extra-large);
  padding-bottom: var(--spacing-extra-extra-large);
}

.pv8 {
  padding-top: var(--spacing-extra-extra-extra-large);
  padding-bottom: var(--spacing-extra-extra-extra-large);
}

.ph1 {
  padding-left: var(--spacing-extra-small);
  padding-right: var(--spacing-extra-small);
}

.ph2 {
  padding-left: var(--spacing-small);
  padding-right: var(--spacing-small);
}

.ph3 {
  padding-left: var(--spacing-medium);
  padding-right: var(--spacing-medium);
}

.ph4 {
  padding-left: var(--spacing-extra-medium);
  padding-right: var(--spacing-extra-medium);
}

.ph5 {
  padding-left: var(--spacing-large);
  padding-right: var(--spacing-large);
}

.ph6 {
  padding-left: var(--spacing-extra-large);
  padding-right: var(--spacing-extra-large);
}

.ph7 {
  padding-left: var(--spacing-extra-extra-large);
  padding-right: var(--spacing-extra-extra-large);
}

.ph8 {
  padding-left: var(--spacing-extra-extra-extra-large);
  padding-right: var(--spacing-extra-extra-extra-large);
}

.ma0 {
  margin: var(--spacing-none);
}
.ma1 {
  margin: var(--spacing-extra-small);
}
.ma2 {
  margin: var(--spacing-small);
}
.ma3 {
  margin: var(--spacing-medium);
}
.ma4 {
  margin: var(--spacing-extra-medium);
}
.ma5 {
  margin: var(--spacing-large);
}
.ma6 {
  margin: var(--spacing-extra-large);
}
.ma7 {
  margin: var(--spacing-extra-extra-large);
}
.ma8 {
  margin: var(--spacing-extra-extra-extra-large);
}

.ml0 {
  margin-left: var(--spacing-none);
}
.ml1 {
  margin-left: var(--spacing-extra-small);
}
.ml2 {
  margin-left: var(--spacing-small);
}
.ml3 {
  margin-left: var(--spacing-medium);
}
.ml4 {
  margin-left: var(--spacing-extra-medium);
}
.ml5 {
  margin-left: var(--spacing-large);
}
.ml6 {
  margin-left: var(--spacing-extra-large);
}
.ml7 {
  margin-left: var(--spacing-extra-extra-large);
}
.ml8 {
  margin-left: var(--spacing-extra-extra-extra-large);
}

.mr0 {
  margin-right: var(--spacing-none);
}
.mr1 {
  margin-right: var(--spacing-extra-small);
}
.mr2 {
  margin-right: var(--spacing-small);
}
.mr3 {
  margin-right: var(--spacing-medium);
}
.mr4 {
  margin-right: var(--spacing-extra-medium);
}
.mr5 {
  margin-right: var(--spacing-large);
}
.mr6 {
  margin-right: var(--spacing-extra-large);
}
.mr7 {
  margin-right: var(--spacing-extra-extra-large);
}
.mr8 {
  margin-right: var(--spacing-extra-extra-extra-large);
}

.mb0 {
  margin-bottom: var(--spacing-none);
}
.mb1 {
  margin-bottom: var(--spacing-extra-small);
}
.mb2 {
  margin-bottom: var(--spacing-small);
}
.mb3 {
  margin-bottom: var(--spacing-medium);
}
.mb4 {
  margin-bottom: var(--spacing-extra-medium);
}
.mb5 {
  margin-bottom: var(--spacing-large);
}
.mb6 {
  margin-bottom: var(--spacing-extra-large);
}
.mb7 {
  margin-bottom: var(--spacing-extra-extra-large);
}
.mb8 {
  margin-bottom: var(--spacing-extra-extra-extra-large);
}

.mt0 {
  margin-top: var(--spacing-none);
}
.mt1 {
  margin-top: var(--spacing-extra-small);
}
.mt2 {
  margin-top: var(--spacing-small);
}
.mt3 {
  margin-top: var(--spacing-medium);
}
.mt4 {
  margin-top: var(--spacing-extra-medium);
}
.mt5 {
  margin-top: var(--spacing-large);
}
.mt6 {
  margin-top: var(--spacing-extra-large);
}
.mt7 {
  margin-top: var(--spacing-extra-extra-large);
}
.mt8 {
  margin-top: var(--spacing-extra-extra-extra-large);
}

.mv0 {
  margin-top: var(--spacing-none);
  margin-bottom: var(--spacing-none);
}
.mv1 {
  margin-top: var(--spacing-extra-small);
  margin-bottom: var(--spacing-extra-small);
}
.mv2 {
  margin-top: var(--spacing-small);
  margin-bottom: var(--spacing-small);
}
.mv3 {
  margin-top: var(--spacing-medium);
  margin-bottom: var(--spacing-medium);
}
.mv4 {
  margin-top: var(--spacing-extra-medium);
  margin-bottom: var(--spacing-extra-medium);
}
.mv5 {
  margin-top: var(--spacing-large);
  margin-bottom: var(--spacing-large);
}
.mv6 {
  margin-top: var(--spacing-extra-large);
  margin-bottom: var(--spacing-extra-large);
}
.mv7 {
  margin-top: var(--spacing-extra-extra-large);
  margin-bottom: var(--spacing-extra-extra-large);
}
.mv8 {
  margin-top: var(--spacing-extra-extra-extra-large);
  margin-bottom: var(--spacing-extra-extra-extra-large);
}

.mh0 {
  margin-left: var(--spacing-none);
  margin-right: var(--spacing-none);
}
.mh1 {
  margin-left: var(--spacing-extra-small);
  margin-right: var(--spacing-extra-small);
}
.mh2 {
  margin-left: var(--spacing-small);
  margin-right: var(--spacing-small);
}
.mh3 {
  margin-left: var(--spacing-medium);
  margin-right: var(--spacing-medium);
}
.mh4 {
  margin-left: var(--spacing-extra-medium);
  margin-right: var(--spacing-extra-medium);
}
.mh5 {
  margin-left: var(--spacing-large);
  margin-right: var(--spacing-large);
}
.mh6 {
  margin-left: var(--spacing-extra-large);
  margin-right: var(--spacing-extra-large);
}
.mh7 {
  margin-left: var(--spacing-extra-extra-large);
  margin-right: var(--spacing-extra-extra-large);
}
.mh8 {
  margin-left: var(--spacing-extra-extra-extra-large);
  margin-right: var(--spacing-extra-extra-extra-large);
}

@media screen and (min-width: 30em) {
  .pa0-ns {
    padding: var(--spacing-none);
  }
  .pa1-ns {
    padding: var(--spacing-extra-small);
  }
  .pa2-ns {
    padding: var(--spacing-small);
  }
  .pa3-ns {
    padding: var(--spacing-medium);
  }
  .pa4-ns {
    padding: var(--spacing-extra-medium);
  }
  .pa5-ns {
    padding: var(--spacing-large);
  }
  .pa6-ns {
    padding: var(--spacing-extra-large);
  }
  .pa7-ns {
    padding: var(--spacing-extra-extra-large);
  }
  .pa8-ns {
    padding: var(--spacing-extra-extra-extra-large);
  }

  .pl0-ns {
    padding-left: var(--spacing-none);
  }
  .pl1-ns {
    padding-left: var(--spacing-extra-small);
  }
  .pl2-ns {
    padding-left: var(--spacing-small);
  }
  .pl3-ns {
    padding-left: var(--spacing-medium);
  }
  .pl4-ns {
    padding-left: var(--spacing-extra-medium);
  }
  .pl5-ns {
    padding-left: var(--spacing-large);
  }
  .pl6-ns {
    padding-left: var(--spacing-extra-large);
  }
  .pl7-ns {
    padding-left: var(--spacing-extra-extra-large);
  }
  .pl8-ns {
    padding-left: var(--spacing-extra-extra-extra-large);
  }

  .pr0-ns {
    padding-right: var(--spacing-none);
  }
  .pr1-ns {
    padding-right: var(--spacing-extra-small);
  }
  .pr2-ns {
    padding-right: var(--spacing-small);
  }
  .pr3-ns {
    padding-right: var(--spacing-medium);
  }
  .pr4-ns {
    padding-right: var(--spacing-extra-medium);
  }
  .pr5-ns {
    padding-right: var(--spacing-large);
  }
  .pr6-ns {
    padding-right: var(--spacing-extra-large);
  }
  .pr7-ns {
    padding-right: var(--spacing-extra-extra-large);
  }
  .pr8-ns {
    padding-right: var(--spacing-extra-extra-extra-large);
  }

  .pb0-ns {
    padding-bottom: var(--spacing-none);
  }
  .pb1-ns {
    padding-bottom: var(--spacing-extra-small);
  }
  .pb2-ns {
    padding-bottom: var(--spacing-small);
  }
  .pb3-ns {
    padding-bottom: var(--spacing-medium);
  }
  .pb4-ns {
    padding-bottom: var(--spacing-extra-medium);
  }
  .pb5-ns {
    padding-bottom: var(--spacing-large);
  }
  .pb6-ns {
    padding-bottom: var(--spacing-extra-large);
  }
  .pb7-ns {
    padding-bottom: var(--spacing-extra-extra-large);
  }
  .pb8-ns {
    padding-bottom: var(--spacing-extra-extra-extra-large);
  }

  .pt0-ns {
    padding-top: var(--spacing-none);
  }
  .pt1-ns {
    padding-top: var(--spacing-extra-small);
  }
  .pt2-ns {
    padding-top: var(--spacing-small);
  }
  .pt3-ns {
    padding-top: var(--spacing-medium);
  }
  .pt4-ns {
    padding-top: var(--spacing-extra-medium);
  }
  .pt5-ns {
    padding-top: var(--spacing-large);
  }
  .pt6-ns {
    padding-top: var(--spacing-extra-large);
  }
  .pt7-ns {
    padding-top: var(--spacing-extra-extra-large);
  }
  .pt9-ns {
    padding-top: var(--spacing-extra-extra-extra-large);
  }

  .pv0-ns {
    padding-top: var(--spacing-none);
    padding-bottom: var(--spacing-none);
  }
  .pv1-ns {
    padding-top: var(--spacing-extra-small);
    padding-bottom: var(--spacing-extra-small);
  }
  .pv2-ns {
    padding-top: var(--spacing-small);
    padding-bottom: var(--spacing-small);
  }
  .pv3-ns {
    padding-top: var(--spacing-medium);
    padding-bottom: var(--spacing-medium);
  }
  .pv4-ns {
    padding-top: var(--spacing-extra-medium);
    padding-bottom: var(--spacing-extra-medium);
  }
  .pv5-ns {
    padding-top: var(--spacing-large);
    padding-bottom: var(--spacing-large);
  }
  .pv6-ns {
    padding-top: var(--spacing-extra-large);
    padding-bottom: var(--spacing-extra-large);
  }
  .pv7-ns {
    padding-top: var(--spacing-extra-extra-large);
    padding-bottom: var(--spacing-extra-extra-large);
  }

  .pv8-ns {
    padding-top: var(--spacing-extra-extra-extra-large);
    padding-bottom: var(--spacing-extra-extra-extra-large);
  }

  .ph0-ns {
    padding-left: var(--spacing-none);
    padding-right: var(--spacing-none);
  }
  .ph1-ns {
    padding-left: var(--spacing-extra-small);
    padding-right: var(--spacing-extra-small);
  }
  .ph2-ns {
    padding-left: var(--spacing-small);
    padding-right: var(--spacing-small);
  }
  .ph3-ns {
    padding-left: var(--spacing-medium);
    padding-right: var(--spacing-medium);
  }

  .ph4-ns {
    padding-left: var(--spacing-extra-medium);
    padding-right: var(--spacing-extra-medium);
  }

  .ph5-ns {
    padding-left: var(--spacing-large);
    padding-right: var(--spacing-large);
  }

  .ph6-ns {
    padding-left: var(--spacing-extra-large);
    padding-right: var(--spacing-extra-large);
  }

  .ph7-ns {
    padding-left: var(--spacing-extra-extra-large);
    padding-right: var(--spacing-extra-extra-large);
  }

  .ph8-ns {
    padding-left: var(--spacing-extra-extra-extra-large);
    padding-right: var(--spacing-extra-extra-extra-large);
  }

  .ma0-ns {
    margin: var(--spacing-none);
  }
  .ma1-ns {
    margin: var(--spacing-extra-small);
  }
  .ma2-ns {
    margin: var(--spacing-small);
  }
  .ma3-ns {
    margin: var(--spacing-medium);
  }
  .ma4-ns {
    margin: var(--spacing-extra-medium);
  }
  .ma5-ns {
    margin: var(--spacing-large);
  }
  .ma6-ns {
    margin: var(--spacing-extra-large);
  }
  .ma7-ns {
    margin: var(--spacing-extra-extra-large);
  }
  .ma8-ns {
    margin: var(--spacing-extra-extra-extra-large);
  }

  .ml0-ns {
    margin-left: var(--spacing-none);
  }
  .ml1-ns {
    margin-left: var(--spacing-extra-small);
  }
  .ml2-ns {
    margin-left: var(--spacing-small);
  }
  .ml3-ns {
    margin-left: var(--spacing-medium);
  }
  .ml4-ns {
    margin-left: var(--spacing-extra-medium);
  }
  .ml5-ns {
    margin-left: var(--spacing-large);
  }
  .ml6-ns {
    margin-left: var(--spacing-extra-large);
  }
  .ml7-ns {
    margin-left: var(--spacing-extra-extra-large);
  }
  .ml8-ns {
    margin-left: var(--spacing-extra-extra-extra-large);
  }

  .mr0-ns {
    margin-right: var(--spacing-none);
  }
  .mr1-ns {
    margin-right: var(--spacing-extra-small);
  }
  .mr2-ns {
    margin-right: var(--spacing-small);
  }
  .mr3-ns {
    margin-right: var(--spacing-medium);
  }
  .mr4-ns {
    margin-right: var(--spacing-extra-medium);
  }
  .mr5-ns {
    margin-right: var(--spacing-large);
  }
  .mr6-ns {
    margin-right: var(--spacing-extra-large);
  }
  .mr7-ns {
    margin-right: var(--spacing-extra-extra-large);
  }
  .mr8-ns {
    margin-right: var(--spacing-extra-extra-extra-large);
  }

  .mb0-ns {
    margin-bottom: var(--spacing-none);
  }
  .mb1-ns {
    margin-bottom: var(--spacing-extra-small);
  }
  .mb2-ns {
    margin-bottom: var(--spacing-small);
  }
  .mb3-ns {
    margin-bottom: var(--spacing-medium);
  }
  .mb4-ns {
    margin-bottom: var(--spacing-extra-medium);
  }
  .mb5-ns {
    margin-bottom: var(--spacing-large);
  }
  .mb6-ns {
    margin-bottom: var(--spacing-extra-large);
  }
  .mb7-ns {
    margin-bottom: var(--spacing-extra-extra-large);
  }
  .mb8-ns {
    margin-bottom: var(--spacing-extra-extra-extra-large);
  }

  .mt0-ns {
    margin-top: var(--spacing-none);
  }
  .mt1-ns {
    margin-top: var(--spacing-extra-small);
  }
  .mt2-ns {
    margin-top: var(--spacing-small);
  }
  .mt3-ns {
    margin-top: var(--spacing-medium);
  }
  .mt4-ns {
    margin-top: var(--spacing-extra-medium);
  }
  .mt5-ns {
    margin-top: var(--spacing-large);
  }
  .mt6-ns {
    margin-top: var(--spacing-extra-large);
  }
  .mt7-ns {
    margin-top: var(--spacing-extra-extra-large);
  }
  .mt8-ns {
    margin-top: var(--spacing-extra-extra-extra-large);
  }

  .mv0-ns {
    margin-top: var(--spacing-none);
    margin-bottom: var(--spacing-none);
  }
  .mv1-ns {
    margin-top: var(--spacing-extra-small);
    margin-bottom: var(--spacing-extra-small);
  }
  .mv2-ns {
    margin-top: var(--spacing-small);
    margin-bottom: var(--spacing-small);
  }
  .mv3-ns {
    margin-top: var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
  }
  .mv4-ns {
    margin-top: var(--spacing-extra-medium);
    margin-bottom: var(--spacing-extra-medium);
  }
  .mv5-ns {
    margin-top: var(--spacing-large);
    margin-bottom: var(--spacing-large);
  }
  .mv6-ns {
    margin-top: var(--spacing-extra-large);
    margin-bottom: var(--spacing-extra-large);
  }
  .mv7-ns {
    margin-top: var(--spacing-extra-extra-large);
    margin-bottom: var(--spacing-extra-extra-large);
  }
  .mv8-ns {
    margin-top: var(--spacing-extra-extra-extra-large);
    margin-bottom: var(--spacing-extra-extra-extra-large);
  }

  .mh0-ns {
    margin-left: var(--spacing-none);
    margin-right: var(--spacing-none);
  }
  .mh1-ns {
    margin-left: var(--spacing-extra-small);
    margin-right: var(--spacing-extra-small);
  }
  .mh2-ns {
    margin-left: var(--spacing-small);
    margin-right: var(--spacing-small);
  }
  .mh3-ns {
    margin-left: var(--spacing-medium);
    margin-right: var(--spacing-medium);
  }
  .mh4-ns {
    margin-left: var(--spacing-extra-medium);
    margin-right: var(--spacing-extra-medium);
  }
  .mh5-ns {
    margin-left: var(--spacing-large);
    margin-right: var(--spacing-large);
  }
  .mh6-ns {
    margin-left: var(--spacing-extra-large);
    margin-right: var(--spacing-extra-large);
  }
  .mh7-ns {
    margin-left: var(--spacing-extra-extra-large);
    margin-right: var(--spacing-extra-extra-large);
  }
  .mh8-ns {
    margin-left: var(--spacing-extra-extra-extra-large);
    margin-right: var(--spacing-extra-extra-extra-large);
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .pa0-m {
    padding: var(--spacing-none);
  }
  .pa1-m {
    padding: var(--spacing-extra-small);
  }
  .pa2-m {
    padding: var(--spacing-small);
  }
  .pa3-m {
    padding: var(--spacing-medium);
  }
  .pa4-m {
    padding: var(--spacing-extra-medium);
  }
  .pa5-m {
    padding: var(--spacing-large);
  }
  .pa6-m {
    padding: var(--spacing-extra-large);
  }
  .pa7-m {
    padding: var(--spacing-extra-extra-large);
  }
  .pa8-m {
    padding: var(--spacing-extra-extra-extra-large);
  }

  .pl0-m {
    padding-left: var(--spacing-none);
  }
  .pl1-m {
    padding-left: var(--spacing-extra-small);
  }
  .pl2-m {
    padding-left: var(--spacing-small);
  }
  .pl3-m {
    padding-left: var(--spacing-medium);
  }
  .pl4-m {
    padding-left: var(--spacing-extra-medium);
  }
  .pl5-m {
    padding-left: var(--spacing-large);
  }
  .pl6-m {
    padding-left: var(--spacing-extra-large);
  }
  .pl7-m {
    padding-left: var(--spacing-extra-extra-large);
  }
  .pl8-m {
    padding-left: var(--spacing-extra-extra-extra-large);
  }

  .pr0-m {
    padding-right: var(--spacing-none);
  }
  .pr1-m {
    padding-right: var(--spacing-extra-small);
  }
  .pr2-m {
    padding-right: var(--spacing-small);
  }
  .pr3-m {
    padding-right: var(--spacing-medium);
  }
  .pr4-m {
    padding-right: var(--spacing-extra-medium);
  }
  .pr5-m {
    padding-right: var(--spacing-large);
  }
  .pr6-m {
    padding-right: var(--spacing-extra-large);
  }
  .pr7-m {
    padding-right: var(--spacing-extra-extra-large);
  }
  .pr8-m {
    padding-right: var(--spacing-extra-extra-extra-large);
  }

  .pb0-m {
    padding-bottom: var(--spacing-none);
  }
  .pb1-m {
    padding-bottom: var(--spacing-extra-small);
  }
  .pb2-m {
    padding-bottom: var(--spacing-small);
  }
  .pb3-m {
    padding-bottom: var(--spacing-medium);
  }
  .pb4-m {
    padding-bottom: var(--spacing-extra-medium);
  }
  .pb5-m {
    padding-bottom: var(--spacing-large);
  }
  .pb6-m {
    padding-bottom: var(--spacing-extra-large);
  }
  .pb7-m {
    padding-bottom: var(--spacing-extra-extra-large);
  }
  .pb8-m {
    padding-bottom: var(--spacing-extra-extra-extra-large);
  }

  .pt0-m {
    padding-top: var(--spacing-none);
  }
  .pt1-m {
    padding-top: var(--spacing-extra-small);
  }
  .pt2-m {
    padding-top: var(--spacing-small);
  }
  .pt3-m {
    padding-top: var(--spacing-medium);
  }
  .pt4-m {
    padding-top: var(--spacing-extra-medium);
  }
  .pt5-m {
    padding-top: var(--spacing-large);
  }
  .pt6-m {
    padding-top: var(--spacing-extra-large);
  }
  .pt7-m {
    padding-top: var(--spacing-extra-extra-large);
  }
  .pt9-m {
    padding-top: var(--spacing-extra-extra-extra-large);
  }

  .pv0-m {
    padding-top: var(--spacing-none);
    padding-bottom: var(--spacing-none);
  }
  .pv1-m {
    padding-top: var(--spacing-extra-small);
    padding-bottom: var(--spacing-extra-small);
  }
  .pv2-m {
    padding-top: var(--spacing-small);
    padding-bottom: var(--spacing-small);
  }
  .pv3-m {
    padding-top: var(--spacing-medium);
    padding-bottom: var(--spacing-medium);
  }
  .pv4-m {
    padding-top: var(--spacing-extra-medium);
    padding-bottom: var(--spacing-extra-medium);
  }
  .pv5-m {
    padding-top: var(--spacing-large);
    padding-bottom: var(--spacing-large);
  }
  .pv6-m {
    padding-top: var(--spacing-extra-large);
    padding-bottom: var(--spacing-extra-large);
  }
  .pv7-m {
    padding-top: var(--spacing-extra-extra-large);
    padding-bottom: var(--spacing-extra-extra-large);
  }

  .pv8-m {
    padding-top: var(--spacing-extra-extra-extra-large);
    padding-bottom: var(--spacing-extra-extra-extra-large);
  }

  .ph0-m {
    padding-left: var(--spacing-none);
    padding-right: var(--spacing-none);
  }
  .ph1-m {
    padding-left: var(--spacing-extra-small);
    padding-right: var(--spacing-extra-small);
  }
  .ph2-m {
    padding-left: var(--spacing-small);
    padding-right: var(--spacing-small);
  }
  .ph3-m {
    padding-left: var(--spacing-medium);
    padding-right: var(--spacing-medium);
  }
  .ph4-m {
    padding-left: var(--spacing-extra-medium);
    padding-right: var(--spacing-extra-medium);
  }

  .ph5-m {
    padding-left: var(--spacing-large);
    padding-right: var(--spacing-large);
  }

  .ph6-m {
    padding-left: var(--spacing-extra-large);
    padding-right: var(--spacing-extra-large);
  }

  .ph7-m {
    padding-left: var(--spacing-extra-extra-large);
    padding-right: var(--spacing-extra-extra-large);
  }

  .ph8-m {
    padding-left: var(--spacing-extra-extra-extra-large);
    padding-right: var(--spacing-extra-extra-extra-large);
  }

  .ma0-m {
    margin: var(--spacing-none);
  }
  .ma1-m {
    margin: var(--spacing-extra-small);
  }
  .ma2-m {
    margin: var(--spacing-small);
  }
  .ma3-m {
    margin: var(--spacing-medium);
  }
  .ma4-m {
    margin: var(--spacing-extra-medium);
  }
  .ma5-m {
    margin: var(--spacing-large);
  }
  .ma6-m {
    margin: var(--spacing-extra-large);
  }
  .ma7-m {
    margin: var(--spacing-extra-extra-large);
  }
  .ma8-m {
    margin: var(--spacing-extra-extra-extra-large);
  }

  .ml0-m {
    margin-left: var(--spacing-none);
  }
  .ml1-m {
    margin-left: var(--spacing-extra-small);
  }
  .ml2-m {
    margin-left: var(--spacing-small);
  }
  .ml3-m {
    margin-left: var(--spacing-medium);
  }
  .ml4-m {
    margin-left: var(--spacing-extra-medium);
  }
  .ml5-m {
    margin-left: var(--spacing-large);
  }
  .ml6-m {
    margin-left: var(--spacing-extra-large);
  }
  .ml7-m {
    margin-left: var(--spacing-extra-extra-large);
  }
  .ml8-m {
    margin-left: var(--spacing-extra-extra-extra-large);
  }

  .mr0-m {
    margin-right: var(--spacing-none);
  }
  .mr1-m {
    margin-right: var(--spacing-extra-small);
  }
  .mr2-m {
    margin-right: var(--spacing-small);
  }
  .mr3-m {
    margin-right: var(--spacing-medium);
  }
  .mr4-m {
    margin-right: var(--spacing-extra-medium);
  }
  .mr5-m {
    margin-right: var(--spacing-large);
  }
  .mr6-m {
    margin-right: var(--spacing-extra-large);
  }
  .mr7-m {
    margin-right: var(--spacing-extra-extra-large);
  }
  .mr8-m {
    margin-right: var(--spacing-extra-extra-extra-large);
  }

  .mb0-m {
    margin-bottom: var(--spacing-none);
  }
  .mb1-m {
    margin-bottom: var(--spacing-extra-small);
  }
  .mb2-m {
    margin-bottom: var(--spacing-small);
  }
  .mb3-m {
    margin-bottom: var(--spacing-medium);
  }
  .mb4-m {
    margin-bottom: var(--spacing-extra-medium);
  }
  .mb5-m {
    margin-bottom: var(--spacing-large);
  }
  .mb6-m {
    margin-bottom: var(--spacing-extra-large);
  }
  .mb7-m {
    margin-bottom: var(--spacing-extra-extra-large);
  }
  .mb8-m {
    margin-bottom: var(--spacing-extra-extra-extra-large);
  }

  .mt0-m {
    margin-top: var(--spacing-none);
  }
  .mt1-m {
    margin-top: var(--spacing-extra-small);
  }
  .mt2-m {
    margin-top: var(--spacing-small);
  }
  .mt3-m {
    margin-top: var(--spacing-medium);
  }
  .mt4-m {
    margin-top: var(--spacing-extra-medium);
  }
  .mt5-m {
    margin-top: var(--spacing-large);
  }
  .mt6-m {
    margin-top: var(--spacing-extra-large);
  }
  .mt7-m {
    margin-top: var(--spacing-extra-extra-large);
  }
  .mt8-m {
    margin-top: var(--spacing-extra-extra-extra-large);
  }

  .mv0-m {
    margin-top: var(--spacing-none);
    margin-bottom: var(--spacing-none);
  }
  .mv1-m {
    margin-top: var(--spacing-extra-small);
    margin-bottom: var(--spacing-extra-small);
  }
  .mv2-m {
    margin-top: var(--spacing-small);
    margin-bottom: var(--spacing-small);
  }
  .mv3-m {
    margin-top: var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
  }
  .mv4-m {
    margin-top: var(--spacing-extra-medium);
    margin-bottom: var(--spacing-extra-medium);
  }
  .mv5-m {
    margin-top: var(--spacing-large);
    margin-bottom: var(--spacing-large);
  }
  .mv6-m {
    margin-top: var(--spacing-extra-large);
    margin-bottom: var(--spacing-extra-large);
  }
  .mv7-m {
    margin-top: var(--spacing-extra-extra-large);
    margin-bottom: var(--spacing-extra-extra-large);
  }
  .mv8-m {
    margin-top: var(--spacing-extra-extra-extra-large);
    margin-bottom: var(--spacing-extra-extra-extra-large);
  }

  .mh0-m {
    margin-left: var(--spacing-none);
    margin-right: var(--spacing-none);
  }
  .mh1-m {
    margin-left: var(--spacing-extra-small);
    margin-right: var(--spacing-extra-small);
  }
  .mh2-m {
    margin-left: var(--spacing-small);
    margin-right: var(--spacing-small);
  }
  .mh3-m {
    margin-left: var(--spacing-medium);
    margin-right: var(--spacing-medium);
  }

  .mh4-m {
    margin-left: var(--spacing-extra-medium);
    margin-right: var(--spacing-extra-medium);
  }
  .mh5-m {
    margin-left: var(--spacing-large);
    margin-right: var(--spacing-large);
  }
  .mh6-m {
    margin-left: var(--spacing-extra-large);
    margin-right: var(--spacing-extra-large);
  }
  .mh7-m {
    margin-left: var(--spacing-extra-extra-large);
    margin-right: var(--spacing-extra-extra-large);
  }
  .mh8-m {
    margin-left: var(--spacing-extra-extra-extra-large);
    margin-right: var(--spacing-extra-extra-extra-large);
  }
}

@media screen and (min-width: 60em) {
  .pa0-l {
    padding: var(--spacing-none);
  }
  .pa1-l {
    padding: var(--spacing-extra-small);
  }
  .pa2-l {
    padding: var(--spacing-small);
  }
  .pa3-l {
    padding: var(--spacing-medium);
  }
  .pa4-l {
    padding: var(--spacing-extra-medium);
  }
  .pa5-l {
    padding: var(--spacing-large);
  }
  .pa6-l {
    padding: var(--spacing-extra-large);
  }
  .pa7-l {
    padding: var(--spacing-extra-extra-large);
  }
  .pa8-l {
    padding: var(--spacing-extra-extra-extra-large);
  }

  .pl0-l {
    padding-left: var(--spacing-none);
  }
  .pl1-l {
    padding-left: var(--spacing-extra-small);
  }
  .pl2-l {
    padding-left: var(--spacing-small);
  }
  .pl3-l {
    padding-left: var(--spacing-medium);
  }
  .pl4-l {
    padding-left: var(--spacing-extra-medium);
  }
  .pl5-l {
    padding-left: var(--spacing-large);
  }
  .pl6-l {
    padding-left: var(--spacing-extra-large);
  }
  .pl7-l {
    padding-left: var(--spacing-extra-extra-large);
  }
  .pl8-l {
    padding-left: var(--spacing-extra-extra-extra-large);
  }

  .pr0-l {
    padding-right: var(--spacing-none);
  }
  .pr1-l {
    padding-right: var(--spacing-extra-small);
  }
  .pr2-l {
    padding-right: var(--spacing-small);
  }
  .pr3-l {
    padding-right: var(--spacing-medium);
  }
  .pr4-l {
    padding-right: var(--spacing-extra-medium);
  }
  .pr5-l {
    padding-right: var(--spacing-large);
  }
  .pr6-l {
    padding-right: var(--spacing-extra-large);
  }
  .pr7-l {
    padding-right: var(--spacing-extra-extra-large);
  }
  .pr8-l {
    padding-right: var(--spacing-extra-extra-extra-large);
  }

  .pb0-l {
    padding-bottom: var(--spacing-none);
  }
  .pb1-l {
    padding-bottom: var(--spacing-extra-small);
  }
  .pb2-l {
    padding-bottom: var(--spacing-small);
  }
  .pb3-l {
    padding-bottom: var(--spacing-medium);
  }
  .pb4-l {
    padding-bottom: var(--spacing-extra-medium);
  }
  .pb5-l {
    padding-bottom: var(--spacing-large);
  }
  .pb6-l {
    padding-bottom: var(--spacing-extra-large);
  }
  .pb7-l {
    padding-bottom: var(--spacing-extra-extra-large);
  }
  .pb8-l {
    padding-bottom: var(--spacing-extra-extra-extra-large);
  }

  .pt0-l {
    padding-top: var(--spacing-none);
  }
  .pt1-l {
    padding-top: var(--spacing-extra-small);
  }
  .pt2-l {
    padding-top: var(--spacing-small);
  }
  .pt3-l {
    padding-top: var(--spacing-medium);
  }
  .pt4-l {
    padding-top: var(--spacing-extra-medium);
  }
  .pt5-l {
    padding-top: var(--spacing-large);
  }
  .pt6-l {
    padding-top: var(--spacing-extra-large);
  }
  .pt7-l {
    padding-top: var(--spacing-extra-extra-large);
  }
  .pt9-l {
    padding-top: var(--spacing-extra-extra-extra-large);
  }

  .pv0-l {
    padding-top: var(--spacing-none);
    padding-bottom: var(--spacing-none);
  }
  .pv1-l {
    padding-top: var(--spacing-extra-small);
    padding-bottom: var(--spacing-extra-small);
  }
  .pv2-l {
    padding-top: var(--spacing-small);
    padding-bottom: var(--spacing-small);
  }
  .pv3-l {
    padding-top: var(--spacing-medium);
    padding-bottom: var(--spacing-medium);
  }
  .pv4-l {
    padding-top: var(--spacing-extra-medium);
    padding-bottom: var(--spacing-extra-medium);
  }
  .pv5-l {
    padding-top: var(--spacing-large);
    padding-bottom: var(--spacing-large);
  }
  .pv6-l {
    padding-top: var(--spacing-extra-large);
    padding-bottom: var(--spacing-extra-large);
  }
  .pv7-l {
    padding-top: var(--spacing-extra-extra-large);
    padding-bottom: var(--spacing-extra-extra-large);
  }

  .pv8-l {
    padding-top: var(--spacing-extra-extra-extra-large);
    padding-bottom: var(--spacing-extra-extra-extra-large);
  }

  .ph0-l {
    padding-left: var(--spacing-none);
    padding-right: var(--spacing-none);
  }
  .ph1-l {
    padding-left: var(--spacing-extra-small);
    padding-right: var(--spacing-extra-small);
  }
  .ph2-l {
    padding-left: var(--spacing-small);
    padding-right: var(--spacing-small);
  }
  .ph3-l {
    padding-left: var(--spacing-medium);
    padding-right: var(--spacing-medium);
  }
  .ph4-l {
    padding-left: var(--spacing-extra-medium);
    padding-right: var(--spacing-extra-medium);
  }

  .ph5-l {
    padding-left: var(--spacing-large);
    padding-right: var(--spacing-large);
  }

  .ph6-l {
    padding-left: var(--spacing-extra-large);
    padding-right: var(--spacing-extra-large);
  }

  .ph7-l {
    padding-left: var(--spacing-extra-extra-large);
    padding-right: var(--spacing-extra-extra-large);
  }

  .ph8-l {
    padding-left: var(--spacing-extra-extra-extra-large);
    padding-right: var(--spacing-extra-extra-extra-large);
  }

  .ma0-l {
    margin: var(--spacing-none);
  }
  .ma1-l {
    margin: var(--spacing-extra-small);
  }
  .ma2-l {
    margin: var(--spacing-small);
  }
  .ma3-l {
    margin: var(--spacing-medium);
  }
  .ma4-l {
    margin: var(--spacing-extra-medium);
  }
  .ma5-l {
    margin: var(--spacing-large);
  }
  .ma6-l {
    margin: var(--spacing-extra-large);
  }
  .ma7-l {
    margin: var(--spacing-extra-extra-large);
  }
  .ma8-l {
    margin: var(--spacing-extra-extra-extra-large);
  }

  .ml0-l {
    margin-left: var(--spacing-none);
  }
  .ml1-l {
    margin-left: var(--spacing-extra-small);
  }
  .ml2-l {
    margin-left: var(--spacing-small);
  }
  .ml3-l {
    margin-left: var(--spacing-medium);
  }
  .ml4-l {
    margin-left: var(--spacing-extra-medium);
  }
  .ml5-l {
    margin-left: var(--spacing-large);
  }
  .ml6-l {
    margin-left: var(--spacing-extra-large);
  }
  .ml7-l {
    margin-left: var(--spacing-extra-extra-large);
  }
  .ml8-l {
    margin-left: var(--spacing-extra-extra-extra-large);
  }

  .mr0-l {
    margin-right: var(--spacing-none);
  }
  .mr1-l {
    margin-right: var(--spacing-extra-small);
  }
  .mr2-l {
    margin-right: var(--spacing-small);
  }
  .mr3-l {
    margin-right: var(--spacing-medium);
  }
  .mr4-l {
    margin-right: var(--spacing-extra-medium);
  }
  .mr5-l {
    margin-right: var(--spacing-large);
  }
  .mr6-l {
    margin-right: var(--spacing-extra-large);
  }
  .mr7-l {
    margin-right: var(--spacing-extra-extra-large);
  }
  .mr8-l {
    margin-right: var(--spacing-extra-extra-extra-large);
  }

  .mb0-l {
    margin-bottom: var(--spacing-none);
  }
  .mb1-l {
    margin-bottom: var(--spacing-extra-small);
  }
  .mb2-l {
    margin-bottom: var(--spacing-small);
  }
  .mb3-l {
    margin-bottom: var(--spacing-medium);
  }
  .mb4-l {
    margin-bottom: var(--spacing-extra-medium);
  }
  .mb5-l {
    margin-bottom: var(--spacing-large);
  }
  .mb6-l {
    margin-bottom: var(--spacing-extra-large);
  }
  .mb7-l {
    margin-bottom: var(--spacing-extra-extra-large);
  }
  .mb8-l {
    margin-bottom: var(--spacing-extra-extra-extra-large);
  }

  .mt0-l {
    margin-top: var(--spacing-none);
  }
  .mt1-l {
    margin-top: var(--spacing-extra-small);
  }
  .mt2-l {
    margin-top: var(--spacing-small);
  }
  .mt3-l {
    margin-top: var(--spacing-medium);
  }
  .mt4-l {
    margin-top: var(--spacing-extra-medium);
  }
  .mt5-l {
    margin-top: var(--spacing-large);
  }
  .mt6-l {
    margin-top: var(--spacing-extra-large);
  }
  .mt7-l {
    margin-top: var(--spacing-extra-extra-large);
  }
  .mt8-l {
    margin-top: var(--spacing-extra-extra-extra-large);
  }

  .mv0-l {
    margin-top: var(--spacing-none);
    margin-bottom: var(--spacing-none);
  }
  .mv1-l {
    margin-top: var(--spacing-extra-small);
    margin-bottom: var(--spacing-extra-small);
  }
  .mv2-l {
    margin-top: var(--spacing-small);
    margin-bottom: var(--spacing-small);
  }
  .mv3-l {
    margin-top: var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
  }
  .mv4-l {
    margin-top: var(--spacing-extra-medium);
    margin-bottom: var(--spacing-extra-medium);
  }
  .mv5-l {
    margin-top: var(--spacing-large);
    margin-bottom: var(--spacing-large);
  }
  .mv6-l {
    margin-top: var(--spacing-extra-large);
    margin-bottom: var(--spacing-extra-large);
  }
  .mv7-l {
    margin-top: var(--spacing-extra-extra-large);
    margin-bottom: var(--spacing-extra-extra-large);
  }
  .mv8-l {
    margin-top: var(--spacing-extra-extra-extra-large);
    margin-bottom: var(--spacing-extra-extra-extra-large);
  }

  .mh0-l {
    margin-left: var(--spacing-none);
    margin-right: var(--spacing-none);
  }
  .mh1-l {
    margin-left: var(--spacing-extra-small);
    margin-right: var(--spacing-extra-small);
  }
  .mh2-l {
    margin-left: var(--spacing-small);
    margin-right: var(--spacing-small);
  }
  .mh3-l {
    margin-left: var(--spacing-medium);
    margin-right: var(--spacing-medium);
  }
  .mh4-l {
    margin-left: var(--spacing-extra-medium);
    margin-right: var(--spacing-extra-medium);
  }
  .mh5-l {
    margin-left: var(--spacing-large);
    margin-right: var(--spacing-large);
  }
  .mh6-l {
    margin-left: var(--spacing-extra-large);
    margin-right: var(--spacing-extra-large);
  }
  .mh7-l {
    margin-left: var(--spacing-extra-extra-large);
    margin-right: var(--spacing-extra-extra-large);
  }
  .mh8-l {
    margin-left: var(--spacing-extra-extra-extra-large);
    margin-right: var(--spacing-extra-extra-extra-large);
  }
}

/* Negative margins */
.na1 { margin: calc(-1 * var(--spacing-extra-small)); }
.na2 { margin: calc(-1 * var(--spacing-small)); }
.na3 { margin: calc(-1 * var(--spacing-medium)); }
.na4 {
  margin: -var(--spacing-extra-medium);
}
.na5 {
  margin: -var(--spacing-large);
}
.na6 {
  margin: -var(--spacing-extra-large);
}
.na7 {
  margin: -var(--spacing-extra-extra-large);
}
.na8 {
  margin: -var(--spacing-extra-extra-extra-large);
}

.nl1 { margin-left: calc(-1 * var(--spacing-extra-small)); }
.nl2 { margin-left: calc(-1 * var(--spacing-small)); }
.nl3 { margin-left: calc(-1 * var(--spacing-medium)); }
.nl4 {
  margin-left: -var(--spacing-extra-medium);
}
.nl5 {
  margin-left: -var(--spacing-large);
}
.nl6 {
  margin-left: -var(--spacing-extra-large);
}
.nl7 {
  margin-left: -var(--spacing-extra-extra-large);
}
.nl8 {
  margin-left: -var(--spacing-extra-extra-extra-large);
}

.nr1 { margin-right: calc(-1 * var(--spacing-extra-small)); }
.nr2 { margin-right: calc(-1 * var(--spacing-small)); }
.nr3 { margin-right: calc(-1 * var(--spacing-medium)); }
.nr4 {
  margin-right: -var(--spacing-extra-medium);
}
.nr5 {
  margin-right: -var(--spacing-large);
}
.nr6 {
  margin-right: -var(--spacing-extra-large);
}
.nr7 {
  margin-right: -var(--spacing-extra-extra-large);
}
.nr8 {
  margin-right: -var(--spacing-extra-extra-extra-large);
}

.nb1 { margin-bottom: calc(-1 * var(--spacing-extra-small)); }
.nb2 { margin-bottom: calc(-1 * var(--spacing-small)); }
.nb3 { margin-bottom: calc(-1 * var(--spacing-medium)); }
.nb4 {
  margin-bottom: -var(--spacing-extra-medium);
}
.nb5 {
  margin-bottom: -var(--spacing-large);
}
.nb6 {
  margin-bottom: -var(--spacing-extra-large);
}
.nb7 {
  margin-bottom: -var(--spacing-extra-extra-large);
}
.nb8 {
  margin-bottom: -var(--spacing-extra-extra-extra-large);
}

.nt1 { margin-top: calc(-1 * var(--spacing-extra-small)); }
.nt2 { margin-top: calc(-1 * var(--spacing-small)); }
.nt3 { margin-top: calc(-1 * var(--spacing-medium)); }
.nt4 {
  margin-top: -var(--spacing-extra-medium);
}
.nt5 {
  margin-top: -var(--spacing-large);
}
.nt6 {
  margin-top: -var(--spacing-extra-large);
}
.nt7 {
  margin-top: -var(--spacing-extra-extra-large);
}
.nt8 {
  margin-top: -var(--spacing-extra-extra-extra-large);
}

@media screen and (min-width: 30em) {
    .na1-ns { margin: calc(-1 * var(--spacing-extra-small)); }
  .na2-ns { margin: calc(-1 * var(--spacing-small)); }
  .na3-ns { margin: calc(-1 * var(--spacing-medium)); }
  .na4-ns {
    margin: -var(--spacing-extra-medium);
  }
  .na5-ns {
    margin: -var(--spacing-large);
  }
  .na6-ns {
    margin: -var(--spacing-extra-large);
  }
  .na7-ns {
    margin: -var(--spacing-extra-extra-large);
  }
  .na8-ns {
    margin: -var(--spacing-extra-extra-extra-large);
  }

    .nl1-ns { margin-left: calc(-1 * var(--spacing-extra-small)); }
  .nl2-ns { margin-left: calc(-1 * var(--spacing-small)); }
  .nl3-ns { margin-left: calc(-1 * var(--spacing-medium)); }
  .nl4-ns {
    margin-left: -var(--spacing-extra-medium);
  }
  .nl5-ns {
    margin-left: -var(--spacing-large);
  }
  .nl6-ns {
    margin-left: -var(--spacing-extra-large);
  }
  .nl7-ns {
    margin-left: -var(--spacing-extra-extra-large);
  }
  .nl8-ns {
    margin-left: -var(--spacing-extra-extra-extra-large);
  }

    .nr1-ns { margin-right: calc(-1 * var(--spacing-extra-small)); }
  .nr2-ns { margin-right: calc(-1 * var(--spacing-small)); }
  .nr3-ns { margin-right: calc(-1 * var(--spacing-medium)); }
  .nr4-ns {
    margin-right: -var(--spacing-extra-medium);
  }
  .nr5-ns {
    margin-right: -var(--spacing-large);
  }
  .nr6-ns {
    margin-right: -var(--spacing-extra-large);
  }
  .nr7-ns {
    margin-right: -var(--spacing-extra-extra-large);
  }
  .nr8-ns {
    margin-right: -var(--spacing-extra-extra-extra-large);
  }

    .nb1-ns { margin-bottom: calc(-1 * var(--spacing-extra-small)); }
  .nb2-ns { margin-bottom: calc(-1 * var(--spacing-small)); }
  .nb3-ns { margin-bottom: calc(-1 * var(--spacing-medium)); }
  .nb4-ns {
    margin-bottom: -var(--spacing-extra-medium);
  }
  .nb5-ns {
    margin-bottom: -var(--spacing-large);
  }
  .nb6-ns {
    margin-bottom: -var(--spacing-extra-large);
  }
  .nb7-ns {
    margin-bottom: -var(--spacing-extra-extra-large);
  }
  .nb8-ns {
    margin-bottom: -var(--spacing-extra-extra-extra-large);
  }

    .nt1-ns { margin-top: calc(-1 * var(--spacing-extra-small)); }
  .nt2-ns { margin-top: calc(-1 * var(--spacing-small)); }
  .nt3-ns { margin-top: calc(-1 * var(--spacing-medium)); }
  .nt4-ns {
    margin-top: -var(--spacing-extra-medium);
  }
  .nt5-ns {
    margin-top: -var(--spacing-large);
  }
  .nt6-ns {
    margin-top: -var(--spacing-extra-large);
  }
  .nt7-ns {
    margin-top: -var(--spacing-extra-extra-large);
  }
  .nt8-ns {
    margin-top: -var(--spacing-extra-extra-extra-large);
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
    .na1-m { margin: calc(-1 * var(--spacing-extra-small)); }
  .na2-m { margin: calc(-1 * var(--spacing-small)); }
  .na3-m { margin: calc(-1 * var(--spacing-medium)); }
  .na4-m {
    margin: -var(--spacing-extra-medium);
  }
  .na5-m {
    margin: -var(--spacing-large);
  }
  .na6-m {
    margin: -var(--spacing-extra-large);
  }
  .na7-m {
    margin: -var(--spacing-extra-extra-large);
  }
  .na8-m {
    margin: -var(--spacing-extra-extra-extra-large);
  }

    .nl1-m { margin-left: calc(-1 * var(--spacing-extra-small)); }
  .nl2-m { margin-left: calc(-1 * var(--spacing-small)); }
  .nl3-m { margin-left: calc(-1 * var(--spacing-medium)); }
  .nl4-m {
    margin-left: -var(--spacing-extra-medium);
  }
  .nl5-m {
    margin-left: -var(--spacing-large);
  }
  .nl6-m {
    margin-left: -var(--spacing-extra-large);
  }
  .nl7-m {
    margin-left: -var(--spacing-extra-extra-large);
  }
  .nl8-m {
    margin-left: -var(--spacing-extra-extra-extra-large);
  }

    .nr1-m { margin-right: calc(-1 * var(--spacing-extra-small)); }
  .nr2-m { margin-right: calc(-1 * var(--spacing-small)); }
  .nr3-m { margin-right: calc(-1 * var(--spacing-medium)); }
  .nr4-m {
    margin-right: -var(--spacing-extra-medium);
  }
  .nr5-m {
    margin-right: -var(--spacing-large);
  }
  .nr6-m {
    margin-right: -var(--spacing-extra-large);
  }
  .nr7-m {
    margin-right: -var(--spacing-extra-extra-large);
  }
  .nr8-m {
    margin-right: -var(--spacing-extra-extra-extra-large);
  }

    .nb1-m { margin-bottom: calc(-1 * var(--spacing-extra-small)); }
  .nb2-m { margin-bottom: calc(-1 * var(--spacing-small)); }
  .nb3-m { margin-bottom: calc(-1 * var(--spacing-medium)); }
  .nb4-m {
    margin-bottom: -var(--spacing-extra-medium);
  }
  .nb5-m {
    margin-bottom: -var(--spacing-large);
  }
  .nb6-m {
    margin-bottom: -var(--spacing-extra-large);
  }
  .nb7-m {
    margin-bottom: -var(--spacing-extra-extra-large);
  }
  .nb8-m {
    margin-bottom: -var(--spacing-extra-extra-extra-large);
  }

    .nt1-m { margin-top: calc(-1 * var(--spacing-extra-small)); }
  .nt2-m { margin-top: calc(-1 * var(--spacing-small)); }
  .nt3-m { margin-top: calc(-1 * var(--spacing-medium)); }
  .nt4-m {
    margin-top: -var(--spacing-extra-medium);
  }
  .nt5-m {
    margin-top: -var(--spacing-large);
  }
  .nt6-m {
    margin-top: -var(--spacing-extra-large);
  }
  .nt7-m {
    margin-top: -var(--spacing-extra-extra-large);
  }
  .nt8-m {
    margin-top: -var(--spacing-extra-extra-extra-large);
  }
}

@media screen and (min-width: 60em) {
    .na1-l { margin: calc(-1 * var(--spacing-extra-small)); }
  .na2-l { margin: calc(-1 * var(--spacing-small)); }
  .na3-l { margin: calc(-1 * var(--spacing-medium)); }
  .na4-l {
    margin: -var(--spacing-extra-medium);
  }
  .na5-l {
    margin: -var(--spacing-large);
  }
  .na6-l {
    margin: -var(--spacing-extra-large);
  }
  .na7-l {
    margin: -var(--spacing-extra-extra-large);
  }
  .na8-l {
    margin: -var(--spacing-extra-extra-extra-large);
  }

    .nl1-l { margin-left: calc(-1 * var(--spacing-extra-small)); }
  .nl2-l { margin-left: calc(-1 * var(--spacing-small)); }
  .nl3-l { margin-left: calc(-1 * var(--spacing-medium)); }
  .nl4-l {
    margin-left: -var(--spacing-extra-medium);
  }
  .nl5-l {
    margin-left: -var(--spacing-large);
  }
  .nl6-l {
    margin-left: -var(--spacing-extra-large);
  }
  .nl7-l {
    margin-left: -var(--spacing-extra-extra-large);
  }
  .nl8-l {
    margin-left: -var(--spacing-extra-extra-extra-large);
  }

    .nr1-l { margin-right: calc(-1 * var(--spacing-extra-small)); }
  .nr2-l { margin-right: calc(-1 * var(--spacing-small)); }
  .nr3-l { margin-right: calc(-1 * var(--spacing-medium)); }
  .nr4-l {
    margin-right: -var(--spacing-extra-medium);
  }
  .nr5-l {
    margin-right: -var(--spacing-large);
  }
  .nr6-l {
    margin-right: -var(--spacing-extra-large);
  }
  .nr7-l {
    margin-right: -var(--spacing-extra-extra-large);
  }
  .nr8-l {
    margin-right: -var(--spacing-extra-extra-extra-large);
  }

    .nb1-l { margin-bottom: calc(-1 * var(--spacing-extra-small)); }
  .nb2-l { margin-bottom: calc(-1 * var(--spacing-small)); }
  .nb3-l { margin-bottom: calc(-1 * var(--spacing-medium)); }
  .nb4-l {
    margin-bottom: -var(--spacing-extra-medium);
  }
  .nb5-l {
    margin-bottom: -var(--spacing-large);
  }
  .nb6-l {
    margin-bottom: -var(--spacing-extra-large);
  }
  .nb7-l {
    margin-bottom: -var(--spacing-extra-extra-large);
  }
  .nb8-l {
    margin-bottom: -var(--spacing-extra-extra-extra-large);
  }

    .nt1-l { margin-top: calc(-1 * var(--spacing-extra-small)); }
  .nt2-l { margin-top: calc(-1 * var(--spacing-small)); }
  .nt3-l { margin-top: calc(-1 * var(--spacing-medium)); }
  .nt4-l {
    margin-top: -var(--spacing-extra-medium);
  }
  .nt5-l {
    margin-top: -var(--spacing-large);
  }
  .nt6-l {
    margin-top: -var(--spacing-extra-large);
  }
  .nt7-l {
    margin-top: -var(--spacing-extra-extra-large);
  }
  .nt8-l {
    margin-top: -var(--spacing-extra-extra-extra-large);
  }
}
