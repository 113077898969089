@media only screen and (min-width: 767px) {
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .submit-button-container {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .submit-button-container {
    margin-bottom: 16px;
  }
}

.button-container {
  margin-top: 32px;
}



