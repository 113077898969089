.payment-plan-kard {
  max-width: 760px;
}
.payment-plan-kard-small {
  max-width: 648px;
}

.payment-plan-border {
  border-top: 1px solid var(--ink-lightest);
}
